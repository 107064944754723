import React from 'react';
// import { CreateCampaign } from "src/sections/admin";
import CampaignAuthorization from 'src/sections/admin/campaigns/settings/CampaingAuthorization';

function CampaignSettings() {
  return (
    <div>
      <CampaignAuthorization />
      {/* <CreateCampaign /> */}
    </div>
  );
}
export default CampaignSettings;
