import React, { useEffect, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';

const CampaignAuthorization = () => {
  const [regions, setRegions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllRegions = async () => {
    setIsLoading(true);
    try {
      Get(
        {},
        API_URLS.getUniqueRegions,
        (resp) => {
          setRegions(resp.data.regions);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
        }
      );
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllRegions();
  }, []);

  const columns = [
    {
      accessorKey: 'region', // Accessor for the column
      header: 'Region',
    },
    {
      accessorKey: 'campaignStats', // Accessor for the column
      header: 'Active campaigns',
    },
  ];

  return (
    <div>
      <MaterialReactTable columns={columns} data={regions} loading={isLoading} enableSorting enablePagination />
    </div>
  );
};

export default CampaignAuthorization;
