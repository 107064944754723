import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { enqueueSnackbar } from 'notistack';
import MultiDatePicker from 'src/components/MultiDatePicker';
import { person_general } from '../components/svg';
import { direct_persons, sr_persons, uk_flag_wadda, world_icon } from 'src/images';
import { PersonCard } from '../components';
import { DownloadOutlined } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import CustomDropdown from '../components/CustomDropdown';
import RefreshButton from 'src/components/RefreshButton';

function PersonList({ country_id, sector_id, onBackClick, onSectorIdChange }) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [persons, setPersons] = useState([]);
  const [total, setTotal] = useState(0);
  const [countries, setCountries] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [findHiringPersons, setFindHiringPersons] = useState(false);
  const [findWithEmails, setFindWithEmails] = useState(false);
  const [findWithoutEmails, setFindWithoutEmails] = useState(false);
  const [personTitles, setPersonTitles] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [industries, setIndustries] = useState([]);

  const anchorRef = useRef(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [regions, setRegions] = useState([]);

  const [srCount, setSrCount] = useState(0);
  const [directCount, setDirectCount] = useState(0);
  const getPeople = useCallback(() => {
    setLoading(true);
    Get(
      {
        page,
        pageSize,
        search,
        country_id,
        sector_id,
        selectedCountry: selectedCountry?.id,
        selectedSector: selectedSector?.id,
        findHiringPersons: findHiringPersons.toString(),
        findWithEmails: findWithEmails.toString(),
        findWithoutEmails: findWithoutEmails.toString(),
        industries: selectedOptions,
        startDate: dateRange[0],
        endDate: dateRange[1],
      },
      API_URLS.getAllPersons,
      (resp) => {
        setLoading(false);
        setTotal(resp?.data?.totalCount);
        setPersons(resp?.data?.persons);
        setSrCount(resp?.data?.sr_count);
        setDirectCount(resp?.data?.totalCount - resp?.data?.sr_count);
      },
      (error) => {
        setLoading(false);
        console.error('Error fetching persons:', error);
      }
    );
  }, [
    page,
    pageSize,
    search,
    country_id,
    sector_id,
    findHiringPersons,
    findWithEmails,
    findWithoutEmails,
    selectedCountry,
    selectedSector,
    selectedOptions,
    dateRange,
  ]);

  useEffect(() => {
    getPeople();
  }, [getPeople]);

  const handlePageSizeChange = (e) => {
    setPage(0); // Reset to the first page
    setPageSize(parseInt(e.target.value, 10)); // Update the page size
  };

  const getAllCountriesAndSector = useCallback(() => {
    try {
      Get(
        {},
        API_URLS.getCountriesAndSector,
        (resp) => {
          // setCountries(resp?.data?.countries);
          setSectors(resp?.data?.sectors);
        },
        (error) => {
          console.error('Error fetching countries:', error);
        }
      );
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  }, []);
  
  const getCountries = (region) => {
    try {
      Get(
        {
          region: region,
        },
        API_URLS.getAllCountries,
        (resp) => {
          setCountries(resp?.data);
        },
        (error) => {
          console.error('Error fetching countries:', error);
        }
      );
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  }

  const getRegions=()=>{
    try {
      Get(
        {},
        API_URLS.getAllRegions,
        (resp) => {
          setRegions(resp?.data);
        },
        (error) => {
          console.error('Error fetching regions:', error);
        }
      )
    } catch (error) {
      console.error('Error fetching regions:', error);
    }
  }

  useEffect(() => {
    getAllCountriesAndSector();
    getRegions();
  }, [getAllCountriesAndSector]);

  const getIndustries = () => {
    try {
      Get(
        {},
        API_URLS.getUniqueIndustriesFromCompanies,
        (resp) => {
          setIndustries(resp?.data?.industries);
        },
        (error) => {
          console.error('Error fetching countries:', error);
        }
      );
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  useEffect(() => {
    getIndustries();
  }, []);

  const getPersonTitles = () => {
    try {
      Get(
        {},
        API_URLS.getUniquePersonTitles,
        (resp) => {
          setPersonTitles(resp?.data?.titles);
        },
        (error) => {
          console.error('Error fetching countries:', error);
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  useEffect(() => {
    getPersonTitles();
  }, []);

  const handleSelect = (options) => {
    setSelectedOptions(options);
  };

  const handleToggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleCloseDropdown = () => {
    setDropdownOpen(false);
  };

  const handleDownloadPersons = () => {
    try {
      setIsDownloading(true);
      Get(
        {
          country_id,
          sector_id,
          selectedCountry: selectedCountry?.id,
          selectedSector: selectedSector?.id,
          findHiringPersons: findHiringPersons.toString(),
          findWithEmails: findWithEmails.toString(),
          startDate: dateRange[0],
          endDate: dateRange[1],
        },
        API_URLS.downloadPersons,
        (resp) => {
          const persons = resp?.data?.persons;

          // Create a worksheet from the response data
          const worksheet = XLSX.utils.json_to_sheet(persons);

          // Create a new workbook and append the worksheet
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Persons');

          // Convert the workbook to binary and create a Blob
          const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
          });

          // Save the file using file-saver
          const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
          saveAs(blob, 'persons_data.xlsx');
          setIsDownloading(false);
        },
        (error) => {
          setIsDownloading(false);

          console.error('Error fetching persons:', error);
        }
      );
    } catch (error) {
      console.error('Error fetching persons:', error);
    }
  };
  
  return (
    <>
      <div style={{ backgroundColor: '#ECF6FE' }}>
        <div style={{ paddingTop: '10px' }}>
          <Box
            display="flex"
            alignItems="center" // Ensures vertical alignment
            justifyContent="flex-start"
            marginTop="10px"
          >
            <Icon
              icon="material-symbols:arrow-back-rounded"
              style={{ fontSize: '26px', color: '#00C1FE', cursor: 'pointer' }}
              onClick={onBackClick}
            />
            <TextField
              InputProps={{
                startAdornment: (
                  <Icon
                    icon="mingcute:search-line"
                    style={{ fontSize: '24px', fontWeight: 'bold', marginRight: '8px' }}
                  />
                ),
              }}
              variant="outlined"
              placeholder="Search..."
              onChange={(e) => setSearch(e.target.value)}
              sx={{
                width: '50%',
                backgroundColor: 'white',
                borderRadius: '0.3rem',
                marginLeft: '1rem',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {},
                  '&:hover fieldset': {
                    borderColor: '#00C1FE',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00C1FE',
                  },
                },
                '& .MuiInputBase-root': {
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 1px #00C1FE',
                  },
                },
              }}
            />
          </Box>
          <br />
          <Box display="flex" flexWrap="wrap" marginLeft="2.5rem">
            {country_id && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    width: '80px',
                    height: '40px',
                    border: '2px solid #00C1FE',
                    borderRadius: '5px',
                    color: '#ffffff',
                    backgroundColor: '#00C1FE',
                    fontSize: '14px', // Adjust to match the button
                    fontWeight: '500', // Adjust to match the button
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    '&:hover': {
                      backgroundColor: '#00C1FE',
                    },
                  }}
                >
                  {country_id === 1
                    ? 'UK'
                    : country_id === 2
                    ? 'US'
                    : country_id === 3
                    ? 'Canada'
                    : country_id === 4
                    ? 'GERMANY'
                    : country_id === 5
                    ? 'Australia'
                    : country_id === 6
                    ? 'UNITED ARAB EMIRATES'
                    : country_id === 7
                    ? 'Europe'
                    : country_id === 8
                    ? 'Norway'
                    : country_id === 9
                    ? 'France'
                    : country_id === 10
                    ? 'Italy'
                    : 'Worldwide'}
                </Typography>

                {selectedSector && (
                  <>
                    <Icon
                      icon="ph:line-vertical-light"
                      style={{ marginLeft: '-13px', color: '#00C1FE', fontSize: '42px' }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        width: '80px',
                        height: '40px',
                        border: '2px solid #00C1FE',
                        borderRadius: '5px',
                        color: '#ffffff',
                        backgroundColor: '#00C1FE',
                        fontSize: '14px', // Adjust to match the button
                        fontWeight: '500', // Adjust to match the button
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        '&:hover': {
                          backgroundColor: '#00C1FE',
                        },
                      }}
                    >
                      {selectedSector === 1 ? 'Direct' : 'S&R'}
                    </Typography>
                  </>
                )}

                <Icon
                  icon="ph:line-vertical-light"
                  style={{ marginLeft: '-13px', color: '#00C1FE', fontSize: '42px' }}
                />
              </>
            )}

            <Typography
              variant="body2"
              sx={{
                width: '120px',
                height: '40px',
                border: '2px solid #00C1FE',
                borderRadius: '5px',
                color: '#ffffff',
                backgroundColor: '#00C1FE',
                fontSize: '14px',
                fontWeight: '500',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                '&:hover': {
                  backgroundColor: '#00C1FE',
                },
              }}
            >
              Persons
            </Typography>

            <Icon icon="ph:line-vertical-light" style={{ color: '#00C1FE', fontSize: '42px', marginLeft: '-13px' }} />
            <Button
              style={{
                outline: '1px solid rgba(0, 0, 0, 0.12)',
                backdropFilter: 'blur(2px)',
                width: '150px',
                height: '40px',
                backgroundColor: findHiringPersons ? '#00C1FE' : '#ECF6FE',
                color: findHiringPersons ? 'white' : '#00C1FE',
                border: '1px solid #00C1FE',
                marginRight: '5px',
                '&:hover': {
                  backgroundColor: '#00C1FE',
                  color: 'white',
                },
              }}
              size="small"
              onClick={() => {
                setFindHiringPersons(!findHiringPersons);
                // setFindWithEmails(false);
                // setFindWithoutEmails(false);
              }}
            >
              Hiring Persons
            </Button>
            <Button
              style={{
                outline: '1px solid rgba(0, 0, 0, 0.12)',
                backdropFilter: 'blur(2px)',
                width: '150px',
                height: '40px',
                backgroundColor: findWithEmails ? '#00C1FE' : '#ECF6FE',
                color: findWithEmails ? 'white' : '#00C1FE',
                border: '1px solid #00C1FE',
                marginRight: '5px',
                '&:hover': {
                  backgroundColor: '#00C1FE',
                  color: 'white',
                },
              }}
              size="small"
              onClick={() => {
                setFindWithEmails(!findWithEmails);
                // setFindHiringPersons(false);
                setFindWithoutEmails(false);
              }}
            >
              With Emails
            </Button>
            <Button
              style={{
                outline: '1px solid rgba(0, 0, 0, 0.12)',
                backdropFilter: 'blur(2px)',
                width: '150px',
                height: '40px',
                backgroundColor: findWithoutEmails ? '#00C1FE' : '#ECF6FE',
                color: findWithoutEmails ? 'white' : '#00C1FE',
                border: '1px solid #00C1FE',
                marginRight: '5px',
                '&:hover': {
                  backgroundColor: '#00C1FE',
                  color: 'white',
                },
              }}
              size="small"
              onClick={() => {
                setFindWithoutEmails(!findWithoutEmails);
                // setFindHiringPersons(false);
                setFindWithEmails(false);
              }}
            >
              Without Emails
            </Button>

            {!country_id && !sector_id && (
              <>
                <Autocomplete
                  options={regions}
                  getOptionLabel={(option) => option?.region} // Adjust based on your backend response
                  onChange={(event, newValue) => {
                    getCountries(newValue?.region);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Region"
                      size="small"
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        width: '190px',
                        height: '40px',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}
                />
                {countries?.length > 0 && (
                  <Autocomplete
                    options={countries}
                    getOptionLabel={(option) => option?.country} // Adjust based on your backend response
                    onChange={(event, newValue) => {
                      setSelectedCountry(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Country"
                        size="small"
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '0.3rem',
                          width: '190px',
                          height: '40px',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#00C1FE',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    )}
                  />
                )}
                <Autocomplete
                  options={sectors}
                  getOptionLabel={(option) => option?.sector} // Adjust based on your backend response
                  onChange={(event, newValue) => {
                    setSelectedSector(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Sector"
                      size="small"
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        marginLeft: '5px',
                        width: '190px',
                        marginRight: '5px',
                        height: '40px',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}
                />
              </>
            )}
            {country_id && !sector_id && (
              <Autocomplete
                options={sectors}
                getOptionLabel={(option) => option?.sector} // Adjust based on your backend response
                onChange={(event, newValue) => {
                  setSelectedSector(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Sector"
                    size="small"
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      marginLeft: '5px',
                      marginRight: '5px',
                      width: '190px',
                      height: '40px',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                )}
              />
            )}
            <Button
              style={{
                outline: '1px solid rgba(0, 0, 0, 0.12)',
                backdropFilter: 'blur(2px)',
                width: '150px',
                height: '40px',
                backgroundColor: '#ECF6FE',
                color: '#00C1FE',
                border: '1px solid #00C1FE',
                marginRight: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                overflow: 'hidden', // Ensure overflow is hidden
                textOverflow: 'ellipsis', // Handle text overflow
                whiteSpace: 'nowrap', // Prevent text wrapping
              }}
              size="small"
              ref={anchorRef}
              onClick={handleToggleDropdown}
            >
              <span
                style={{
                  flex: 1, // Ensure the span takes available space
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {selectedOptions?.length === 0 && 'Select Industry'}
                {selectedOptions?.length === 1 && selectedOptions[0]}
                {selectedOptions?.length > 1 && (
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px', // Space between the count and text
                    }}
                  >
                    <span
                      style={{
                        color: 'white',
                        backgroundColor: '#00C1FE',
                        borderRadius: '50%',
                        height: '20px',
                        width: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center', // Center the count
                      }}
                    >
                      {selectedOptions?.length}
                    </span>
                    <span>Selected</span>
                  </span>
                )}
              </span>
              <Icon icon="ri:arrow-down-s-fill" style={{ fontSize: '20px', marginLeft: '12px' }} />
            </Button>
            {dropdownOpen && (
              <CustomDropdown
                options={industries}
                onSelect={handleSelect}
                selectedOptions={selectedOptions} // Pass selected options
                anchorEl={anchorRef.current}
                onClose={handleCloseDropdown}
                open={dropdownOpen} // Pass open state
              />
            )}

            <MultiDatePicker onChange={(range) => setDateRange(range)} numberOfMonths={2} height="40px" width="230px" />
            <RefreshButton onClick={getPeople} />
            {findWithEmails && (
              <Button
                style={{
                  outline: '1px solid rgba(0, 0, 0, 0.12)',
                  backdropFilter: 'blur(2px)',
                  width: '150px',
                  height: '40px',
                  backgroundColor: findWithEmails ? '#00C1FE' : '#ECF6FE',
                  color: findWithEmails ? 'white' : '#00C1FE',
                  border: '1px solid #00C1FE',
                  marginRight: '5px',
                  '&:hover': {
                    backgroundColor: '#00C1FE',
                    color: 'white',
                  },
                }}
                size="small"
                onClick={() => handleDownloadPersons()}
              >
                {isDownloading && <CircularProgress size={5} color="inherit" />}
                {!isDownloading && (
                  <>
                    <DownloadOutlined />
                    Download
                  </>
                )}
              </Button>
            )}
          </Box>
          <br />
          <Grid container spacing={2} style={{ marginLeft: '2.5rem' }}>
            <Grid item xs="none" sm={12} md={2}>
              <Card
                style={{
                  width: '100%',
                  height: '100px',
                  borderRadius: '0.3rem',
                }}
              >
                <img
                  src={
                    country_id === 1
                      ? uk_flag_wadda
                      : country_id === 2
                      ? 'https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg'
                      : country_id === 3
                      ? 'https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg'
                      : country_id === 4
                      ? 'https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg'
                      : country_id === 6
                      ? 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg'
                      : country_id === 7
                      ? 'https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg'
                      : country_id === 5
                      ? 'https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg'
                      : country_id === 8
                      ? 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg'
                      : country_id === 9
                      ? 'https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg'
                      : country_id === 10
                      ? 'https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg'
                      : world_icon
                  }
                  alt="companies"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '0.3rem',
                  }}
                />
              </Card>
            </Grid>
            <Grid item xs="none" sm={12} md={9}>
              <Card
                style={{
                  width: '100%',
                  height: '100px',
                  borderRadius: '0.3rem',
                }}
              >
                <Typography
                  style={{
                    textAlign: 'center',
                    fontSize: '20px',
                    color: '#3f51b5',
                    paddingTop: '5px',
                  }}
                >
                  {country_id === 1
                    ? 'UK '
                    : country_id === 2
                    ? 'US '
                    : country_id === 3
                    ? 'Canada '
                    : country_id === 3
                    ? 'UAE '
                    : 'Total '}{' '}
                  People Stats
                </Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center" width="90%" margin="auto">
                  <Tooltip title="S&R Persons" arrow placement="top">
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelectedSector(2);
                        onSectorIdChange(2);
                      }}
                    >
                      <img
                        src={sr_persons}
                        alt="companies"
                        style={{ width: '25px', height: '25px', objectFit: 'fill' }}
                      />
                      &nbsp;&nbsp;
                      <Typography
                        style={{
                          textAlign: 'center',
                          fontSize: '20px',
                          color: '#3f51b5',
                          paddingTop: '5px',
                        }}
                      >
                        {srCount || 0}
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Tooltip title="Total Persons" arrow placement="top">
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelectedSector(null);
                        onSectorIdChange(null);
                      }}
                    >
                      <img
                        src={person_general}
                        alt="companies"
                        style={{ width: '25px', height: '25px', objectFit: 'fill' }}
                      />
                      &nbsp;&nbsp;
                      <Typography
                        style={{
                          textAlign: 'center',
                          fontSize: '20px',
                          color: '#3f51b5',
                          paddingTop: '5px',
                        }}
                      >
                        {parseInt(srCount) + parseInt(directCount) || 0}
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Tooltip title="Direct Persons" arrow placement="top">
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelectedSector(1);
                        onSectorIdChange(1);
                      }}
                    >
                      <img
                        src={direct_persons}
                        alt="companies"
                        style={{ width: '25px', height: '25px', objectFit: 'fill' }}
                      />
                      &nbsp;&nbsp;
                      <Typography
                        style={{
                          textAlign: 'center',
                          fontSize: '20px',
                          color: '#3f51b5',
                          paddingTop: '5px',
                        }}
                      >
                        {directCount || 0}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>
              </Card>
            </Grid>
          </Grid>
          <br />
          <Box display="flex" marginLeft="2.5rem">
            <p
              style={{
                textAlign: 'start',
                fontSize: '15px',
                color: '#3f51b5',
                paddingTop: '5px',
              }}
            >
              About {total || 0} results
            </p>
          </Box>
          {loading && (
            <p style={{ marginLeft: '2.5rem', fontWeight: 'bold' }}>
              <CircularProgress />
            </p>
          )}
          <TablePagination
            component="div"
            count={total}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handlePageSizeChange}
          />
          <div style={{ margin: '1rem' }}>
            {persons?.map((person) => (
              <PersonCard person={person} key={person.id} />
            ))}
          </div>
        </div>
      </div>
      <br />

      <div
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        style={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          width: 60, // Set equal width and height
          height: 60, // Set equal width and height
          borderRadius: '50%', // Circular shape
          backgroundColor: '#00C1FE',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center', // Center the icon
        }}
      >
        <Tooltip arrow placement="top" title="Scroll to top">
          <Icon icon="ph:arrow-up-bold" style={{ fontSize: '30px' }} />
        </Tooltip>
      </div>
    </>
  );
}

export default PersonList;
