import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Get } from 'src/actions/API/apiActions';
import  { DateObject } from 'react-multi-date-picker';

import { API_URLS } from 'src/constants/apiURLs';
import { format } from 'date-fns';
import MaterialReactTable from 'material-react-table';
import { Icon } from '@iconify/react';
import { EmailRecipentsLineChart } from '.';
import MultiDatePicker from 'src/components/MultiDatePicker';
import EmailBasedReporting from '../../templates/components/EmailBasedReporting';

function EmailSendingReporting({ users }) {
  const [recipentsStats, setRecipentsStats] = useState([]);
  const [value, setValue] = useState([new DateObject().setDay(1), new DateObject().add(1, 'month').setDay(1)]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [reciepentsOverTime, setReciepentsOverTime] = useState([]);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 3,
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  const [formattedDates, setFormattedDates] = useState([null, null]);
  const [searchString, setSearchString] = useState('');

  const columns = useMemo(
    () => [
      {
        accessorKey: 'campaign.id',
        header: 'ID',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'campaign.name',
        header: 'Campaign Name',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'recipents',
        header: 'Recipients',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'date',
        header: 'Date',
        enableClickToCopy: true,
        minWidth: 170,
        Cell: ({ cell }) => {
          // Convert the date string to the desired format
          const formattedDate = format(new Date(cell.getValue()), 'MMM d, yyyy, hh:mm a');
          return (
            <Box component="span">
              <Typography
                variant="body2"
                style={{
                  color: 'black',
                  backgroundColor: 'transparent',
                  padding: '5px',
                  borderRadius: '5px',
                  width: '150px',
                  textAlign: 'center',
                }}
              >
                {formattedDate}
              </Typography>
            </Box>
          );
        },
      },
    ],
    []
  );

  const getReciepents = () => {
    try {
      setIsLoading(true);
      Get(
        {
          campaign_id: selectedCampaign,
          from_date: formattedDates[0],
          to_date: formattedDates[1],
          page: pagination.pageIndex,
          size: pagination.pageSize,
        },
        API_URLS.getCampaignRecipents,
        (resp) => {
          setRecipentsStats(resp?.data?.data);
          //   enqueueSnackbar('Reciepents fetched successfully', { variant: 'success' });
          setIsLoading(false);
          setTotalCount(resp?.data?.count);
        },
        (error) => {
          
          setIsLoading(false);
          setIsError(true);
          // enqueueSnackbar('Failed to get reciepents', { variant: 'error' });
        }
      );
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      // enqueueSnackbar('Something went wrong , please try again', { variant: 'error' });
    }
  };

  useEffect(() => {
    getReciepents();
  }, [selectedCampaign, formattedDates, pagination.pageIndex, pagination.pageSize]);

  const getAllCampaigns = useCallback(() => {
    try {
      Get(
        {},
        API_URLS.findAll,
        (response) => {
          setCampaigns(response.data);
        },
        (error) => {
          
        }
      );
    } catch (error) {
      // enqueueSnackbar('Something went wrong at server', { variant: 'error' });
    }
  }, []);

  useEffect(() => {
    getAllCampaigns();
  }, [getAllCampaigns]);

  const getCampaignRecipentsStatsForLineGraph = () => {
    try {
      Get(
        {
          campaignId: selectedCampaign,
          from_date: formattedDates[0],
          to_date: formattedDates[1],
        },
        API_URLS.getCampaignRecipentsStatsForLineGraph,
        (resp) => {
          //   enqueueSnackbar('Campaign Reciepents stats fetched successfully', { variant: 'success' });
          setReciepentsOverTime(resp?.data);
        },
        (error) => {
          // enqueueSnackbar('Failed to get the reciepents stats', { variant: 'error' });
        }
      );
    } catch (error) {
      // enqueueSnackbar('Something went wrong at server', { variant: 'error' });
    }
  };

  useEffect(() => {
    getCampaignRecipentsStatsForLineGraph();
  }, [selectedCampaign, formattedDates]);

  // const transformData = (data) => {
  //   const groupedData = data.reduce((acc, item) => {
  //     const date = item.date.split('T')[0]; // Extract date part only

  //     if (!acc[date]) {
  //       acc[date] = {
  //         date: date,
  //         items: [],
  //       };
  //     }

  //     acc[date].items.push(item);
  //     return acc;
  //   }, {});

  //   return Object.entries(groupedData).map(([date, { items }]) => ({
  //     date,
  //     items,
  //   }));
  // };

  // const formattedData = transformData(recipentsStats);

  const [status, setStatus] = useState();
  const [emailSentBy, setEmailSentBy] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const statuses = [
    {
      id: 'is_email_delivered',
      label: 'Delivered',
    },
    {
      id: 'is_email_opened',
      label: 'Opened',
    },
    {
      id: 'is_email_bounced',
      label: 'Bounced',
    },
    {
      id: 'is_email_clicked',
      label: 'Clicked',
    },
    {
      id: 'is_email_unsubscribed',
      label: 'Unsubscribed',
    },
    {
      id: 'is_lead_complained',
      label: 'Complaints',
    },
    {
      id: 'is_email_spammed',
      label: 'Marked as Spams',
    },
  ];

  const sentBy = [
    {
      id: 'amna',
      label: 'Amna Yaseen',
    },
    {
      id: 'maryam',
      label: 'Maryam Riaz',
    },
  ];

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={2}>
          <Autocomplete
            options={campaigns}
            size="normal"
            getOptionLabel={(option) => option?.name}
            onChange={(event, newValue) => {
              setSelectedCampaign(newValue ? newValue.id : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Campaign"
                sx={{
                  backgroundColor: 'white',
                  marginLeft: '5px',
                  borderRadius: '0.3rem',
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#00C1FE',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#00C1FE',
                    },
                  },
                  '& .MuiInputBase-root': {
                    '&.Mui-focused': {
                      boxShadow: '0 0 0 1px #00C1FE',
                    },
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Autocomplete
            options={statuses}
            size="normal"
            getOptionLabel={(option) => option?.label}
            onChange={(event, newValue) => {
              setStatus(newValue ? newValue.id : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Status"
                sx={{
                  backgroundColor: 'white',
                  marginLeft: '5px',
                  borderRadius: '0.3rem',
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#00C1FE',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#00C1FE',
                    },
                  },
                  '& .MuiInputBase-root': {
                    '&.Mui-focused': {
                      boxShadow: '0 0 0 1px #00C1FE',
                    },
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Autocomplete
            options={users}
            size="normal"
            getOptionLabel={(option) => option?.first_name + ' ' + option?.last_name}
            onChange={(event, newValue) => {
              setSelectedUser(newValue ? newValue.id : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select User"
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '0.3rem',
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#00C1FE',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#00C1FE',
                    },
                  },
                  '& .MuiInputBase-root': {
                    '&.Mui-focused': {
                      boxShadow: '0 0 0 1px #00C1FE',
                    },
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Autocomplete
            options={sentBy}
            size="normal"
            getOptionLabel={(option) => option?.label}
            onChange={(event, newValue) => {
              setEmailSentBy(newValue ? newValue.label : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select BD"
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '0.3rem',
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#00C1FE',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#00C1FE',
                    },
                  },
                  '& .MuiInputBase-root': {
                    '&.Mui-focused': {
                      boxShadow: '0 0 0 1px #00C1FE',
                    },
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <MultiDatePicker
            onChange={(dateRange) => {
              setFormattedDates(dateRange);
            }}
            numberOfMonths={2}
            height="54px"
            width="220px"
          />
        </Grid>
      </Grid>
      <br />
      <Box display="flex" marginLeft="5px">
        <Typography variant="h4">Job Posting</Typography>
      </Box>
      <br />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <div style={{ margin: '5px' }}>
            <MaterialReactTable
              columns={columns}
              data={recipentsStats}
              getSubRows={(row) => row.items}
              enableColumnActions={false}
              rowCount={totalCount}
              enableColumnFilters={false}
              enableTopToolbar={false}
              enableGrouping
              defaultColumn={{
                size: 50,
              }}
              manualPagination
              onColumnFiltersChange={setColumnFilters}
              onGlobalFilterChange={setSearchString}
              onPaginationChange={setPagination}
              onSortingChange={setSorting}
              muiTableBodyRowProps={{ hover: false }}
              state={{
                columnFilters,
                isLoading,
                pagination,
                showAlertBanner: isError,
                searchString,
                sorting,
              }}
              muiTableProps={{
                sx: {
                  borderRadius: '30px',
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <EmailRecipentsLineChart recipientsOverTime={reciepentsOverTime} campaigns={campaigns} />
        </Grid>
      </Grid>
      <br />

      <EmailBasedReporting
        users={users}
        selectedUser={selectedUser}
        emailSentBy={emailSentBy}
        status={status}
        formattedDates={formattedDates}
      />
    </div>
  );
}

export default EmailSendingReporting;
