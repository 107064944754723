import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';
import MaterialReactTable from 'material-react-table';
import { CampaignReport } from './templates/components';
import { Refresh } from '@mui/icons-material';
import EmailBasedReporting from './templates/components/EmailBasedReporting';

export default function CampaignSection() {
  const [countries, setCountries] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [counters, setCounters] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [campaignId, setCampaignId] = useState(null);
  const [showCampaignReport, setShowCampaignReport] = useState(false);

  const getAllCountries = () => {
    try {
      Get(
        {},
        API_URLS.getAllCountries,
        (response) => {
          setCountries(response.data);
        },
        (error) => {
        }
      );
    } catch (error) {
    }
  };

  useEffect(() => {
    getAllCountries();
  }, []);

  const getAllSectors = () => {
    try {
      Get(
        {},
        API_URLS.getAllSectors,
        (resp) => {
          setSectors(resp.data);
        },
        (error) => {
        }
      );
    } catch (error) {
    }
  };

  useEffect(() => {
    getAllSectors();
  }, []);

  const getAllCampaigns = useCallback(() => {
    try {
      setIsLoading(true);
      Get(
        {
          country_id: selectedCountry?.id,
          sector_id: selectedSector?.id,
        },
        API_URLS.findAll,
        (response) => {
          setIsLoading(false);
          setCampaigns(response.data);
        },
        (error) => {
          setIsError(true);
          setIsLoading(false);
        }
      );
    } catch (error) {
      // enqueueSnackbar('Something went wrong at server', { variant: 'error' });
      setIsLoading(false);
    }
  }, [selectedCountry, selectedSector, enqueueSnackbar]);

  useEffect(() => {
    getAllCampaigns();
  }, [selectedCountry, selectedSector, getAllCampaigns, counters]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Campaign Name',
        minWidth: 170,
        Cell: ({ cell, row }) => (
          <div
            onClick={() => {
              setShowCampaignReport(true);
              setCampaignId(row?.original);
            }}
            style={{ cursor: 'pointer', color: '#0ec4fe' }}
          >
            {cell.getValue()}
          </div>
        ),
      },
      {
        accessorKey: 'country.country',
        header: 'Country',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'sector.sector',
        header: 'Sector',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableClickToCopy: true,
        minWidth: 170,
      },
      // {
      //   accessorKey: 'counter',
      //   header: 'Job post processed',
      //   enableClickToCopy: true,
      //   minWidth: 170,
      // },
      // {
      //   accessorKey: 'job_posts_count',
      //   header: 'Job Posts added',
      //   enableClickToCopy: true,
      //   minWidth: 170,
      // },
    ],
    [counters] // Recalculate when counters change
  );
  

  return (
    <div style={{ width: '98%', margin: 'auto' }}>
      <CampaignReport
        campaign={campaignId}
        onBack={() => {
          setShowCampaignReport(false);
          setCampaignId(null);
        }}
      />
      {/* {!showCampaignReport && !campaignId ? (
        <>
          <h1 style={{ fontWeight: 'bold', fontSize: '30px' }}>Campaigns</h1>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                options={countries}
                getOptionLabel={(option) => option.country} // Adjust based on your backend response
                onChange={(event, newValue) => {
                  setSelectedCountry(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Country"
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                options={sectors}
                getOptionLabel={(option) => option.sector}
                onChange={(event, newValue) => {
                  setSelectedSector(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Sector"
                    fullWidth
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '0.3rem',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Button
                variant="outlined"
                className="text-[#00C1FE] border-[#00C1FE] hover:bg-blue-50 hover:border-[#00C1FE] mr-4 h-[52px] w-32"
                style={{ outline: '1px solid rgba(0, 0, 0, 0.12)', backdropFilter: 'blur(4px)' }}
                onClick={() => {
                  getAllCampaigns();
                }}
              >
                <Refresh /> &nbsp; Refresh
              </Button>
            </Grid>
          </Grid>

          <br />
          <br />
          <br />

          <div >
            <MaterialReactTable
              columns={columns}
              data={campaigns}
              enableColumnActions={false}
              enableColumnFilters={false}
              enableTopToolbar={false}
              defaultColumn={{
                size: 50,
              }}
              manualPagination
              onColumnFiltersChange={setColumnFilters}
              onGlobalFilterChange={setGlobalFilter}
              onPaginationChange={setPagination}
              onSortingChange={setSorting}
              muiTableBodyRowProps={{ hover: false }}
              state={{
                columnFilters,
                globalFilter,
                isLoading,
                pagination,
                showAlertBanner: isError,
                sorting,
              }}
              muiTableProps={{
                sx: {
                  borderRadius: '30px',
                },
              }}
            />
          </div>
        </>
      ) : (
        <CampaignReport
          campaign={campaignId}
          onBack={() => {
            setShowCampaignReport(false);
            setCampaignId(null);
          }}
        />
      )} */}
      <br />
      {/* <EmailBasedReporting /> */}
    </div>
  );
}
