import { Icon } from '@iconify/react';
import { Box, Button, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { Get, Post } from 'src/actions/API/apiActions';
import { CSVLink } from 'react-csv'; // Import CSVLink from react-csv
import { enqueueSnackbar } from 'notistack';
import { API_URLS } from 'src/constants/apiURLs';
import { useCallback } from 'react';
import { Search } from '@mui/icons-material';
// import { formatDistanceToNow } from 'date-fns';

function UnsubEmailsTable({ fromDate, toDate }) {
  // table states
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [searchString, setSearchString] = useState(null);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [unsubEmails, setUnsubEmails] = useState([]);
  const [count, setCount] = useState(0);
  const [isEmailToUnSub, setIsEmailToUnSub] = useState(false);
  const [isDomainToUnSub, setIsDomainToUnSub] = useState(false);
  const [domain, setDomain] = useState();
  const [email, setEmail] = useState();

  const deleteUnsubscribedEmail = (email) => {
    try {
      Post(
        { email: email },
        API_URLS.deleteEmailFromUnsub,
        (resp) => {
          enqueueSnackbar('Email deleted succefully', { variant: 'success' });
        },
        (error) => {
          enqueueSnackbar('Error deleting email', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Error deleting email', { variant: 'error' });
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'person.full_name',
        header: 'Name',
        minWidth: 170,
      },
      {
        accessorKey: 'email_id',
        header: 'Email',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'email',
        header: 'Status',
        enableClickToCopy: true,
        minWidth: 170,
        Cell: ({ cell }) => (
          <Box component="span">
            <>
              <Typography
                variant="body2"
                style={{
                  color: 'white',
                  backgroundColor: '#ff8266',
                  padding: '5px',
                  borderRadius: '5px',
                  width: '100px',
                  textAlign: 'center',
                }}
              >
                Unsubscribed
              </Typography>
            </>
          </Box>
        ),
      },
      {
        accessorKey: 'user.first_name',
        header: 'Lead Expert',
        minWidth: 170,
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        minWidth: 100,
        Cell: ({ row }) => (
          <IconButton onClick={() => deleteUnsubscribedEmail(row.original.email_id)} color="error">
            <Icon icon="mdi:delete" />
          </IconButton>
        ),
      },
      // {
      //   accessorKey: 'createdAt',
      //   header: 'Date',
      //   minWidth: 170,
      //   Cell: ({ cell }) => {
      //     const date = new Date(cell.getValue());
      //     return <Typography variant="body2">{formatDistanceToNow(date, { addSuffix: true })}</Typography>;
      //   },
      // },
    ],
    [] // Recalculate when counters change
  );

  const getRecentUnsubscribedEmails = useCallback(() => {
    try {
      setIsLoading(true);
      Get(
        {
          fromDate: fromDate,
          toDate: toDate,
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
          searchString: searchString,
        },
        API_URLS.getRecentUnSubscribedEmails,
        (resp) => {
          console.log('resp', resp);
          setUnsubEmails(resp?.data?.response);
          setCount(resp?.data?.count || 0);
          setIsLoading(false);
        },
        (error) => {
          console.log('error', error);
          setIsError(true);
          setIsLoading(false);
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
      setIsError(true);
      setIsLoading(false);
    }
  }, [fromDate, toDate, pagination.pageIndex, pagination.pageSize, searchString]);

  useEffect(() => {
    getRecentUnsubscribedEmails();
  }, [getRecentUnsubscribedEmails]);

  // // Convert data to CSV
  const csvData = unsubEmails?.map((email) => ({
    Name: email?.person?.full_name || 'N/A', // Handle missing person object
    Email: email?.email_id || 'N/A', // Use email_id instead of email
    Status: 'Unsubscribed',
    Addedby: email?.user ? `${email.user.first_name || ''} ${email.user.last_name || ''}`.trim() : 'N/A', // Handle missing user object
    Date: email?.createdAt ? new Date(email.createdAt).toLocaleDateString() : 'N/A', // Handle missing date
  }));

  const addEmailToUnsubList = () => {
    // validate email first
    if (!email || email.trim() === '') {
      enqueueSnackbar('Please enter email', { variant: 'error' });
      return;
    }

    // add email to unsub list
    try {
      setIsLoading(true);
      Post(
        {
          email: email,
        },
        API_URLS.addEmailToUnsubList,
        (resp) => {
          console.log('resp', resp);
          enqueueSnackbar('Email added to unsub list successfully', { variant: 'success' });
          getRecentUnsubscribedEmails();
          setEmail('');
          setIsLoading(false);
        },
        (error) => {
          console.log('error', error);
          enqueueSnackbar('Something went wrong', { variant: 'error' });
          setIsError(true);
          setIsLoading(false);
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
      setIsError(true);
      setIsLoading(false);
    }
  };

  const addDomainToUnSub = () => {
    try {
      setIsLoading(true);
      Post(
        {
          domain: domain,
        },
        API_URLS.addDomainToUnSub,
        (resp) => {
          console.log('resp', resp);
          enqueueSnackbar('Domain added to unsub list successfully', { variant: 'success' });
          getRecentUnsubscribedEmails();
          setEmail('');
          setIsLoading(false);
        },
        (error) => {
          console.log('error', error);
          enqueueSnackbar('Something went wrong', { variant: 'error' });
          setIsError(true);
          setIsLoading(false);
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
      setIsError(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <Typography sx={{ marginLeft: '1rem' }} variant="h4">
          Unsubscribed Emails
        </Typography>
        <CSVLink data={csvData || []} filename="unsub-emails.csv">
          <Tooltip title="Download" arrow>
            <Icon icon="ic:outline-download" style={{ fontSize: '25px' }} />
          </Tooltip>
        </CSVLink>
        <Button
          sx={{
            backgroundColor: isDomainToUnSub ? '#ff8266' : 'white',
            color: isDomainToUnSub ? 'white' : 'black',
          }}
          onClick={() => {
            setIsEmailToUnSub(false);
            setIsDomainToUnSub(true);
          }}
        >
          Domain to Unsub
        </Button>
        <Button
          sx={{
            backgroundColor: isEmailToUnSub ? '#ff8266' : 'white',
            color: isEmailToUnSub ? 'white' : 'black',
          }}
          onClick={() => {
            setIsDomainToUnSub(false);
            setIsEmailToUnSub(true);
          }}
        >
          Email to Unsub
        </Button>
      </Box>

      <div style={{ margin: '1rem' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          {isEmailToUnSub && (
            <TextField
              label="Email"
              placeholder="Enter email to add to unsub list"
              variant="outlined"
              size="small"
              maxWidth="100px"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              // style={{ marginBottom: '1rem' }}
            />
          )}
          {isDomainToUnSub && (
            <TextField
              label="Domain"
              placeholder="Enter domain to add to unsub list"
              variant="outlined"
              size="small"
              maxWidth="100px"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              // style={{ marginBottom: '1rem' }}
            />
          )}

          <Button
            variant="contained"
            sx={{
              borderRadius: '5%',
              backgroundColor: '#00C1FE',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: 'unset',
              '&:hover': {
                backgroundColor: '#00C1FE',
                color: 'white',
              },
            }}
            disabled={!isEmailToUnSub && !isDomainToUnSub}
            onClick={() => {
              if (isEmailToUnSub) {
                addEmailToUnsubList();
              } else {
                addDomainToUnSub();
              }
            }}
          >
            {isEmailToUnSub ? 'Add Email to Unsub' : 'Add Domain to Unsub'}
          </Button>
        </Box>
        <br />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <TextField
            label="Search"
            placeholder="Search by domain or email"
            variant="outlined"
            size="small"
            maxWidth="100px"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            // style={{ marginBottom: '1rem' }}
          />
          <Button
            variant="contained"
            sx={{
              borderRadius: '10%',
              backgroundColor: '#00C1FE',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: 'unset',
              '&:hover': {
                backgroundColor: '#00C1FE',
                color: 'white',
              },
            }}
            onClick={getRecentUnsubscribedEmails}
          >
            <Search />
          </Button>
        </Box>
        <br />
        <MaterialReactTable
          columns={columns}
          data={unsubEmails}
          rowCount={count || 0}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableTopToolbar={false}
          defaultColumn={{
            size: 50,
          }}
          manualPagination
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          muiTableBodyRowProps={{ hover: false }}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            sorting,
          }}
          muiTableProps={{
            sx: {
              borderRadius: '30px',
            },
          }}
        />
      </div>
    </>
  );
}

export default UnsubEmailsTable;
