import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Company, CompanyDetails } from './components';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  TablePagination,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import MultiDatePicker from 'src/components/MultiDatePicker';
import { Icon } from '@iconify/react';
import { companySizes } from 'src/constants/constants';
import CustomDropdown from '../components/CustomDropdown';
import { UKFlag, USFlag } from 'src/assets/images';
import { CAFlag, company_direct, company_icon, company_sr, UAEFlag } from '../components/svg';
import { uk_flag_wadda, world_icon } from 'src/images';

function CompaniesList({ country_id, sector_id, onBackClick, onCountryIdChange, onSectorIdChange }) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [total, setTotal] = useState(0);
  const [requestedLevel, setRequestedLevel] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [industries, setIndustries] = useState([]);
  const [companySize, setCompanySize] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const [srCount, setSrCount] = useState(0);
  const [directCount, setDirectCount] = useState(0);
  const [premium_level, setPremiumLevel] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [regions, setRegions] = useState([]);
  // const [cookies, setCookie] = useCookies(['companies']);

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null)

  const dataFetchRef = useRef(false);
  const scrollContainerRef = useRef(null);

  console.log("ksdgfsadfsdafsd",selectedCountry)
  useEffect(() => {
    // Restore scroll position when coming back to the list
    if (!selectedCompany && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollPosition;
    }
  }, [selectedCompany, scrollPosition]);

  const getCompanies = useCallback(() => {

    if (dataFetchRef.current) {
      return;
    }
    setLoading(true);
    Get(
      {
        page,
        pageSize,
        search,
        startDate: dateRange[0],
        endDate: dateRange[1],
        country_id,
        sector_id,
        level: requestedLevel,
        companySize: companySize,
        industries: selectedOptions,
        premium_level,
      },
      API_URLS.getCompanies,
      (resp) => {
        setLoading(false);
        setTotal(resp?.data?.totalCount);
        setCompanies(resp?.data?.companies);
        setSrCount(resp?.data?.sr_count);
        setDirectCount(resp?.data?.direct_count);

        dataFetchRef.current = true;

        //  set companies data to cookies
        //  setCookie('companies', resp?.data?.companies);
      },
      (error) => {
        setLoading(false);
        console.error('Error fetching companies:', error);
      }
    );
  }, [
    page,
    pageSize,
    search,
    country_id,
    sector_id,
    requestedLevel,
    dateRange,
    companySize,
    selectedOptions,
    premium_level,
  ]);

  const getCountries = (region) => {
    try {
      Get(
        {
          region: region,
        },
        API_URLS.getAllCountries,
        (resp) => {
          setCountries(resp?.data);
        },
        (error) => {
          console.error('Error fetching countries:', error);
        }
      );
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  }

  const getRegions=()=>{
    try {
      Get(
        {},
        API_URLS.getAllRegions,
        (resp) => {
          setRegions(resp?.data);
        },
        (error) => {
          console.error('Error fetching regions:', error);
        }
      )
    } catch (error) {
      console.error('Error fetching regions:', error);
    }
  }

  useEffect(() => {
    getCompanies();
    getRegions();
  }, [getCompanies]);

  const handlePageSizeChange = (e) => {
    setPage(0); // Reset to the first page
    setPageSize(parseInt(e.target.value, 10)); // Update the page size
    dataFetchRef.current = false;
  };

  const getIndustries = () => {
    try {
      Get(
        {},
        API_URLS.getUniqueIndustriesFromCompanies,
        (resp) => {
          setIndustries(resp?.data?.industries);
        },
        (error) => {
          console.error('Error fetching countries:', error);
        }
      );
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };
  useEffect(() => {
    getIndustries();
  }, []);
  const handleSelect = (options) => {
    setSelectedOptions(options);
  };

  const handleToggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleCloseDropdown = () => {
    setDropdownOpen(false);
  };
  
  return (
    <>
      <div style={{ backgroundColor: '#ECF6FE' }}>
        <div style={{ paddingTop: '10px' }}>
          {!selectedCompany && (
            <div ref={scrollContainerRef} style={{ height: '100vh', overflowY: 'auto' }}>
              <Box
                display="flex"
                alignItems="center" // Ensures vertical alignment
                justifyContent="flex-start"
                marginTop="20px"
                width="95%"
                margin="auto"
                // sx={{ backgroundColor: 'red' }}
              >
                <Icon
                  icon="material-symbols:arrow-back-rounded"
                  style={{ fontSize: '26px', color: '#00C1FE', cursor: 'pointer' }}
                  onClick={onBackClick}
                />
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <Icon
                        icon="mingcute:search-line"
                        style={{ fontSize: '24px', fontWeight: 'bold', marginRight: '8px' }}
                      />
                    ),
                  }}
                  variant="outlined"
                  placeholder="Search..."
                  onChange={(e) => setSearch(e.target.value)}
                  sx={{
                    width: '100%',
                    backgroundColor: 'white',
                    borderRadius: '0.3rem',
                    marginLeft: '1rem',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {},
                      '&:hover fieldset': {
                        borderColor: '#00C1FE',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#00C1FE',
                      },
                    },
                    '& .MuiInputBase-root': {
                      '&.Mui-focused': {
                        boxShadow: '0 0 0 1px #00C1FE',
                      },
                    },
                  }}
                />
              </Box>
              <br />
              <Box display="flex" flexWrap="wrap" marginLeft="2.5rem" gap={1}>
                {/* {country_id && (
                  <>
                <Typography
                      variant="body2"
                      sx={{
                        width: '80px',
                        height: '40px',
                        border: '2px solid #00C1FE',
                        borderRadius: '5px',
                        color: '#ffffff',
                        backgroundColor: '#00C1FE',
                        fontSize: '14px', // Adjust to match the button
                        fontWeight: '500', // Adjust to match the button
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        '&:hover': {
                          backgroundColor: '#00C1FE',
                        },
                      }}
                    >
                      {country_id === 1
                        ? 'UK'
                        : country_id === 2
                        ? 'US'
                        : country_id === 3
                        ? 'Canada'
                        : country_id === 6
                        ? 'UAE'
                        : 'Worldwide'}
                    </Typography>

                {selectedSector && (
                      <>
                <Icon
                          icon="ph:line-vertical-light"
                          style={{ marginLeft: '-13px', color: '#00C1FE', fontSize: '42px' }}
                        />
                <Typography
                          variant="body2"
                          sx={{
                            width: '80px',
                            height: '40px',
                            border: '2px solid #00C1FE',
                            borderRadius: '5px',
                            color: '#ffffff',
                            backgroundColor: '#00C1FE',
                            fontSize: '14px', // Adjust to match the button
                            fontWeight: '500', // Adjust to match the button
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            '&:hover': {
                              backgroundColor: '#00C1FE',
                            },
                          }}
                        >
                          {selectedSector === 1 ? 'Direct' : 'S&R'}
                        </Typography>
                      </>
                    )}
                    <Icon
                      icon="ph:line-vertical-light"
                      style={{ marginLeft: '-13px', color: '#00C1FE', fontSize: '42px' }}
                    />
                  </>
                )} */}

                {/* <Typography
                  variant="body2"
                  sx={{
                    width: '120px',
                    height: '40px',
                    border: '2px solid #00C1FE',
                    borderRadius: '5px',
                    color: '#ffffff',
                    backgroundColor: '#00C1FE',
                    fontSize: '14px', // Adjust to match the button
                    fontWeight: '500', // Adjust to match the button
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    '&:hover': {
                      backgroundColor: '#00C1FE',
                    },
                  }}
                >
                  Companies
                </Typography>

                <Icon
                  icon="ph:line-vertical-light"
                  style={{ color: '#00C1FE', fontSize: '42px', marginLeft: '-13px' }}
                /> */}
                <ToggleButtonGroup value={requestedLevel} exclusive onChange={(e, value) => setRequestedLevel(value)}>
                  <ToggleButton
                    value={2}
                    style={{
                      backgroundColor: requestedLevel === 2 ? '#00C1FE' : 'white',
                      color: requestedLevel === 2 ? 'white' : '#00C1FE',
                      border: '1px solid #00C1FE',
                      borderRadius: 5,
                      marginRight: 5,
                      width: 'fit-content',
                      height: '40px',
                    }}
                  >
                    Basic
                  </ToggleButton>
                  <ToggleButton
                    value={3}
                    style={{
                      backgroundColor: requestedLevel === 3 ? '#00C1FE' : 'white',
                      color: requestedLevel === 3 ? 'white' : '#00C1FE',
                      border: '1px solid #00C1FE',
                      borderRadius: 5,
                      width: 'fit-content',
                      height: '40px',
                      marginRight: '5px',
                    }}
                  >
                    Advance
                  </ToggleButton>
                </ToggleButtonGroup>

                {(requestedLevel === 2 || requestedLevel === 3) && (
                  <ToggleButtonGroup value={premium_level} exclusive onChange={(e, value) => setPremiumLevel(value)}>
                    <ToggleButton
                      value={false}
                      style={{
                        backgroundColor: premium_level === false ? '#00C1FE' : 'white',
                        color: premium_level === false ? 'white' : '#00C1FE',
                        border: '1px solid #00C1FE',
                        borderRadius: 5,
                        marginRight: 5,
                        width: 'fit-content',
                        height: '40px',
                      }}
                    >
                      New Data
                    </ToggleButton>
                    <ToggleButton
                      value={true}
                      style={{
                        backgroundColor: premium_level === true ? '#00C1FE' : 'white',
                        color: premium_level === true ? 'white' : '#00C1FE',
                        border: '1px solid #00C1FE',
                        borderRadius: 5,
                        width: 'fit-content',
                        height: '40px',
                        marginRight: '5px',
                      }}
                    >
                      Old Data
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}

                {/* <ToggleButtonGroup value={premium_level} exclusive onChange={(e, value) => setPremiumLevel(value)}>
                  <ToggleButton
                    value="pb"
                    style={{
                      backgroundColor: premium_level === 'pb' ? '#00C1FE' : 'white',
                      color: premium_level === 'pb' ? 'white' : '#00C1FE',
                      border: '1px solid #00C1FE',
                      borderRadius: 5,
                      marginRight: 5,
                      width: 'fit-content',
                      height: '40px',
                    }}
                  >
                    Premium Basic
                  </ToggleButton>
                  <ToggleButton
                    value="pa"
                    style={{
                      backgroundColor: premium_level === 'pa' ? '#00C1FE' : 'white',
                      color: premium_level === 'pa' ? 'white' : '#00C1FE',
                      border: '1px solid #00C1FE',
                      borderRadius: 5,
                      width: 'fit-content',
                      height: '40px',
                      marginRight: '5px',
                    }}
                  >
                    Premium Advance
                  </ToggleButton>
                </ToggleButtonGroup> */}
                <Autocomplete
                  id="companySize"
                  name="companySize"
                  options={companySizes}
                  getOptionLabel={(option) => option.size}
                  onChange={(event, newValue) => {
                    setCompanySize(newValue?.size);
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Company Size"
                      sx={{
                        width: '160px',
                        height: '40px',
                        marginRight: '5px',
                        backgroundColor: '#ECF6FE',
                        borderRadius: '0.3rem',
                        border: '2px solid #00C1FE',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}
                />
                <Autocomplete
                  id="region"
                  name="region"
                  options={regions}
                  getOptionLabel={(option) => option.region}
                  onChange={(event, newValue) => {
                    getCountries(newValue?.region);
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Region"
                      sx={{
                        width: '160px',
                        height: '40px',
                        marginRight: '5px',
                        backgroundColor: '#ECF6FE',
                        borderRadius: '0.3rem',
                        border: '2px solid #00C1FE',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}
                />
                {countries?.length > 0 && (
                  <Autocomplete
                    id="country_id"
                    name="country_id"
                    options={countries}
                    getOptionLabel={(option) => option.country}
                    onChange={(event, newValue) => {
                      setSelectedCountry(newValue);
                      onCountryIdChange(newValue?.id);
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Country"
                        sx={{
                          width: '160px',
                          height: '40px',
                          marginRight: '5px',
                          backgroundColor: '#ECF6FE',
                          borderRadius: '0.3rem',
                          border: '2px solid #00C1FE',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#00C1FE',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#00C1FE',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&.Mui-focused': {
                              boxShadow: '0 0 0 1px #00C1FE',
                            },
                          },
                        }}
                      />
                    )}
                  />
                )}
                <Autocomplete
                  id="sector_id"
                  name="sector_id"
                  value={selectedSector === 1 ? 'Direct' : selectedSector === 2 ? 'S&R' : null}
                  options={['Direct', 'S&R']}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => {
                    if (newValue === 'Direct') {
                      setSelectedSector(1);
                      onSectorIdChange(1);
                    } else if (newValue === 'S&R') {
                      setSelectedSector(2);
                      onSectorIdChange(2);
                    } else {
                      setSelectedSector(null);
                      onSectorIdChange(null);
                    }
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Sector"
                      sx={{
                        width: '160px',
                        height: '40px',
                        marginRight: '5px',
                        backgroundColor: '#ECF6FE',
                        borderRadius: '0.3rem',
                        border: '2px solid #00C1FE',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  )}
                />
                <Button
                  style={{
                    outline: '1px solid rgba(0, 0, 0, 0.12)',
                    backdropFilter: 'blur(2px)',
                    width: '150px',
                    height: '40px',
                    backgroundColor: '#ECF6FE',
                    color: '#00C1FE',
                    border: '1px solid #00C1FE',
                    marginRight: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    overflow: 'hidden', // Ensure overflow is hidden
                    textOverflow: 'ellipsis', // Handle text overflow
                    whiteSpace: 'nowrap', // Prevent text wrapping
                  }}
                  size="small"
                  ref={anchorRef}
                  onClick={handleToggleDropdown}
                >
                  <span
                    style={{
                      flex: 1, // Ensure the span takes available space
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {selectedOptions?.length === 0 && 'Select Industry'}
                    {selectedOptions?.length === 1 && selectedOptions[0]}
                    {selectedOptions?.length > 1 && (
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px', // Space between the count and text
                        }}
                      >
                        <span
                          style={{
                            color: 'white',
                            backgroundColor: '#00C1FE',
                            borderRadius: '50%',
                            height: '20px',
                            width: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center', // Center the count
                          }}
                        >
                          {selectedOptions?.length}
                        </span>
                        <span>Selected</span>
                      </span>
                    )}
                  </span>
                  <Icon icon="ri:arrow-down-s-fill" style={{ fontSize: '20px', marginLeft: '12px' }} />
                </Button>
                {dropdownOpen && (
                  <CustomDropdown
                    options={industries}
                    onSelect={handleSelect}
                    selectedOptions={selectedOptions} // Pass selected options
                    anchorEl={anchorRef.current}
                    onClose={handleCloseDropdown}
                    open={dropdownOpen} // Pass open state
                  />
                )}
                <MultiDatePicker
                  dateRange={dateRange}
                  onChange={(range) => setDateRange(range)}
                  numberOfMonths={6}
                  height="40px"
                  width="230px"
                />
                <Button
                  sx={{
                    width: '150px',
                    height: '40px',
                    backgroundColor: '#00C1FE',
                    color: 'white',
                    borderRadius: '0.3rem',
                    '&:hover': {
                      backgroundColor: '#00C1FE',
                    },
                  }}
                  onClick={() => {
                    dataFetchRef.current = false;
                    getCompanies();
                  }}
                >
                  Apply filter
                </Button>
              </Box>

              <br />
              <Grid container spacing={2} style={{ marginLeft: '2.5rem' }}>
                <Grid item xs="none" sm={12} md={2}>
                  <Card
                    style={{
                      width: '100%',
                      height: '100px',
                      borderRadius: '0.3rem',
                    }}
                  >
                    <img
                      src={
                        country_id === 1
                          ? uk_flag_wadda
                          : country_id === 2
                          ? 'https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg'
                          : country_id === 3
                          ? 'https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg'
                          : country_id === 4
                          ? 'https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg'
                          : country_id === 6
                          ? 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg'
                          : country_id === 7
                          ? 'https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg'
                          : country_id === 5
                          ? 'https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg'
                          : country_id === 8
                          ? 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg'
                          : country_id === 9
                          ? 'https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg'
                          : country_id === 10
                          ? 'https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg'
                          : world_icon
                      }
                      alt="companies"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '0.3rem',
                      }}
                    />
                  </Card>
                </Grid>
                <Grid item xs="none" sm={12} md={9}>
                  <Card
                    style={{
                      width: '100%',
                      height: '100px',
                      borderRadius: '0.3rem',
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: 'center',
                        fontSize: '20px',
                        color: '#3f51b5',
                        paddingTop: '5px',
                      }}
                    >
                      {country_id === 1
                        ? 'UK '
                        : country_id === 2
                        ? 'US '
                        : country_id === 3
                        ? 'Canada '
                        : country_id === 6
                        ? 'UAE '
                        : 'Total '}
                      Companies
                    </Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="90%" margin="auto">
                      <Tooltip title="S&R Companies" arrow placement="top">
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            setSelectedSector(2);
                            onSectorIdChange(2);
                          }}
                        >
                          <img
                            src={company_sr}
                            alt="companies"
                            style={{ width: '25px', height: '25px', objectFit: 'fill' }}
                          />
                          &nbsp;&nbsp;
                          <Typography
                            style={{
                              textAlign: 'center',
                              fontSize: '20px',
                              color: '#3f51b5',
                              paddingTop: '5px',
                            }}
                          >
                            {srCount || 0}
                          </Typography>
                        </Box>
                      </Tooltip>
                      <Tooltip title="All Companies" arrow placement="top">
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            setSelectedSector(null);
                            onSectorIdChange(null);
                          }}
                        >
                          <img
                            src={company_icon}
                            alt="companies"
                            style={{ width: '25px', height: '25px', objectFit: 'fill' }}
                          />
                          &nbsp;&nbsp;
                          <Typography
                            style={{
                              textAlign: 'center',
                              fontSize: '20px',
                              color: '#3f51b5',
                              paddingTop: '5px',
                            }}
                          >
                            {srCount + directCount || 0}
                          </Typography>
                        </Box>
                      </Tooltip>
                      <Tooltip title="Direct Companies" arrow placement="top">
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            setSelectedSector(1);
                            onSectorIdChange(1);
                          }}
                        >
                          <img
                            src={company_direct}
                            alt="companies"
                            style={{ width: '25px', height: '25px', objectFit: 'fill' }}
                          />
                          &nbsp;&nbsp;
                          <Typography
                            style={{
                              textAlign: 'center',
                              fontSize: '20px',
                              color: '#3f51b5',
                              paddingTop: '5px',
                            }}
                          >
                            {directCount || 0}
                          </Typography>
                        </Box>
                      </Tooltip>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
              <br />
              <Box display="flex" marginLeft="2.5rem">
                <p
                  style={{
                    textAlign: 'start',
                    fontSize: '15px',
                    color: '#3f51b5',
                    paddingTop: '5px',
                  }}
                >
                  About {total || 0} results
                </p>
              </Box>

              {loading ? (
                <CircularProgress
                  style={{
                    display: 'block',
                    margin: 'auto',
                    marginTop: '20%',
                  }}
                />
              ) : (
                <>
                  <TablePagination
                    component="div"
                    count={total}
                    page={page}
                    onPageChange={(e, newPage) => {
                      setPage(newPage);
                      dataFetchRef.current = false;
                    }}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handlePageSizeChange}
                  />
                  {companies?.map((company) => (
                    // <>
                    //   {company?.peopleCount > 0 && (
                    <Company
                      company={company}
                      key={company.id}
                      onSelect={(company) => {
                        if (scrollContainerRef.current) {
                          setScrollPosition(scrollContainerRef.current.scrollTop);
                        }
                        setSelectedCompany(company);
                      }}
                    />
                    //   )}
                    // </>
                  ))}
                </>
              )}
              <br />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 4,
                  width: '90%',
                  margin: 'auto',
                }}
              ></div>
              <br />

              {/* Scroll to top */}

              <div
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                style={{
                  position: 'fixed',
                  bottom: 20,
                  right: 20,
                  width: 60, // Set equal width and height
                  height: 60, // Set equal width and height
                  borderRadius: '50%', // Circular shape
                  backgroundColor: '#00C1FE',
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center', // Center the icon
                }}
              >
                <Tooltip arrow placement="top" title="Scroll to top">
                  <Icon icon="ph:arrow-up-bold" style={{ fontSize: '30px' }} />
                </Tooltip>
              </div>
            </div>
          )}
        </div>

        {selectedCompany && (
          <CompanyDetails
            company={selectedCompany}
            s={dateRange[0]}
            e={dateRange[1]}
            onBack={(s, e) => {
              setSelectedCompany(null);
              // keep all other filters and search as it is
              dataFetchRef.current = false;
              setDateRange([s, e]);
              getCompanies();
            }}
          />
        )}
      </div>
    </>
  );
}

export default CompaniesList;
