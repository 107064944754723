import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Typography,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Link as RouterLink } from 'react-router-dom';
import logo from 'src/images/logo.png';
import { useSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Post } from 'src/actions/API/apiActions';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { roles } from 'src/constants/constants';
import { API_URLS } from 'src/constants/apiURLs';

const LoginForm = ({onUserLogin}) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isAdmin, setIsAdmin] = useState(false);
  const [verificationCode, setVerificationCode] = useState(null);
  const [isCodeSent, setIsCodeSent] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const initialValues = {
    user_name: '',
    password : '',
    role: '',
  };

  const basicValidationSchema = Yup.object().shape({
    user_name: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });
  
  const sendVerificationCode = (email) => {
    try {
      Post(
        {
          email: email,
        },
        API_URLS.sendVerificationEmail,
        (resp) => {
          setIsCodeSent(true);
        },
        (error) => {
          setIsCodeSent(false);
        }
      );
    } catch (error) {
      setIsCodeSent(false);
    }
  };

  const handleCodeVerification=()=>{
    try {
      Post({
        email: userEmail,
        code: verificationCode,
      },
      API_URLS.verifyVerificationCode,
      resp=>{
        setIsAdmin(true);
        setIsCodeSent(false)
      },
      error=>{
        setIsCodeSent(false)
        setIsAdmin(false)
        enqueueSnackbar("Code didn't match", { variant: 'error' });
      }
    );
    } catch (error) {
        setIsCodeSent(false)
        setIsAdmin(false);
      enqueueSnackbar("Code didn't match", { variant: 'error' });
    }
  }
  return (
    <>
      <div className="container   max-w-[50%] shadow-xl bg-white rounded-3xl mx-auto mb-2 px-10 py-14">
        <div className=" flex justify-center items-center max-w-[500px] mx-auto mb-10">
          <Link to="/" component={RouterLink}>
            <img src={logo} alt="Logo" height={50} width={50} />
          </Link>
          <div>
            <h1 className=" ml-2 flex text-2xl font-medium text-[#00C1FE] items-center justify-center">Ultimate Outsourcing</h1>
            <p className=' ml-6 flex items-center justify-center text-xs'>All Your Reqruitment Worries Outsourced</p>
          </div>
        </div>

        <Typography className=" text-left font-bold text-2xl mb-6">
          Sign in <br></br> to access CRM
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={basicValidationSchema}
          
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
              Post(
                values,
                  API_URLS.login,
                (resp) => {
                  // set Login token to local storage
                  onUserLogin(resp);
                  actions.setSubmitting(false);
                  // set userid in local storage
                  localStorage.setItem('userId', parseInt(resp.id));
                  localStorage.setItem('token', resp.token);
                  setUserEmail(resp.email);
                  const role = resp.role;
                  console.log('dgfsgsdfgsdfgsdfg',resp)
                  window.localStorage.setItem('role', role);
                  window.localStorage.setItem('working_mode', resp?.working_mode);
                  window.localStorage.setItem('features', JSON.stringify(resp?.features));

                  switch (role) {
                    case 'leadExpert':
                      enqueueSnackbar('Login Successfull', { variant: 'success' });
                      navigate('/leadExpert', { replace: true });
                      break;
                    case 'admin':
                      enqueueSnackbar('Select Role', { variant: 'info' });
                      sendVerificationCode(resp.email);
                      break;
                    case 'accounts':
                      enqueueSnackbar('Login Successfull', { variant :"success"});
                      navigate('/acm', { replace: true });
                      break;
                    case 'resourcer':
                      enqueueSnackbar('Login Successfull', { variant: 'success' });
                      navigate('/resourcing', { replace: true });
                      break;
                    case 'leadAdmin':
                      enqueueSnackbar('Login Successfull', { variant: 'success' });
                      navigate('/leadadmin', { replace: true });
                      break;
                    case '360Recruiter':
                      enqueueSnackbar('Login Successfull', { variant: 'success' });
                      navigate('/recruiter', { replace: true });
                      break;
                    case 'resourcer':
                      enqueueSnackbar('Login Successfull', { variant: 'success' });
                      navigate('/resourcing', { replace: true });
                      break;
                    case 'hrm':
                      enqueueSnackbar('Login Successfull', { variant: 'success' });
                      navigate('/hrm', { replace: true });
                      break;
                    case 'QAResourcing':
                      enqueueSnackbar('Login Successfull', { variant: 'success' });
                      navigate('/rqa', { replace: true });
                      break;
                    case 'bdExpert':
                      enqueueSnackbar('Login Successfull', { variant: 'success' });
                      navigate('/bdm', { replace: true });
                      break;
                    default:
                      enqueueSnackbar('You have no Roles assigned by admin');
                      break;
                  }
                },
                (error) => {
                  if (error?.message?.includes('401')) {
                    enqueueSnackbar('Your email is not verified. please verify your email first', { variant: 'info' });
                  } else if (error?.message?.includes('403')) {
                    enqueueSnackbar('Your account is not active. Ask admin to approve your account first', {
                      variant: 'warning',
                    });
                  } else if (error?.message?.includes('404')) {
                    enqueueSnackbar('You are not registered. please Register your account by clicking Get started below', {
                      variant: 'error',
                    });
                  } else if (error?.message?.includes('406')) {
                    enqueueSnackbar('Your Account is blocked due to multi login attempt. Contact Admin for activation', {
                      variant: 'error',
                    });
                  } else {
                    enqueueSnackbar('Invalid Credentials', { variant: 'error' });
                  }

                  actions.setSubmitting(false);
                }
              );
          }}
        >
          {({ values, errors, isSubmitting, handleSubmit, getFieldProps, touched, setFieldValue }) => {
            return (
              <Form>
                <Stack spacing={3}>
                  <TextField
                    className="rounded-full "
                    id="user_name"
                    name="user_name"
                    placeholder="User Name"
                    error={Boolean(touched.user_name && errors.user_name)}
                    helperText={touched.user_name && errors.user_name}
                    {...getFieldProps('user_name')}
                    sx={{
                      width: '100%',

                      borderRadius: '0.5rem',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PersonOutlineIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    name="password"
                    placeholder="Password"
                    type={showPassword ? 'text' : 'password'}
                    sx={{
                      width: '100%',

                      borderRadius: '0.3rem',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#00C1FE',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00C1FE',
                        },
                      },
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 1px #00C1FE',
                        },
                      },
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    {...getFieldProps('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            <Icon icon={showPassword ? 'ph:eye' : 'iconamoon:eye-off'} width="1rem" height="1rem" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSubmit();
                      }
                    }}
                  />
                  {isCodeSent && (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItem: 'center',
                          gap: 2,
                        }}
                      >
                        <TextField
                          name="verificationCode"
                          placeholder="verificationCode"
                          // type={showPassword ? 'text' : 'password'}
                          sx={{
                            width: '100%',

                            borderRadius: '0.3rem',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                          error={Boolean(touched.verificationCode && errors.verificationCode)}
                          helperText={touched.verificationCode && errors.verificationCode}
                          onChange={(e) => {
                            e.preventDefault();
                            setVerificationCode(e.target.value);
                          }}
                        />
                        <LoadingButton
                          fullWidth
                          loading={isSubmitting}
                          loadingIndicator="Logging in..."
                          className="text-white border-[#0fa3de] bg-[#0fa3de]  hover:bg-[#0fa3de] h-[52px] text-xl rounded-lg"
                          onClick={handleCodeVerification}
                        >
                          Verify Code
                        </LoadingButton>
                      </Box>
                    </>
                  )}
                  {isAdmin ? (
                    <Autocomplete
                      options={roles}
                      getOptionLabel={(option) => option.role}
                      sx={{ width: '100%' }}
                      onChange={(event, newRole) => {
                        switch (newRole?.id) {
                          case 'leadExpert':
                            window.localStorage.setItem('role', 'leadExpert');
                            enqueueSnackbar('Login Successfull', { variant: 'success' });
                            navigate('/leadExpert', { replace: true });
                            break;
                          case 'accounts':
                            window.localStorage.setItem('role', 'accounts');
                            enqueueSnackbar('Login Successfull', { variant: 'success' });
                            navigate('/acm', { replace: true });
                            break;
                          case 'bdExpert':
                            window.localStorage.setItem('role', 'bdExpert');
                            enqueueSnackbar('Login Successfull', { variant: 'success' });
                            navigate('/bdexpert', { replace: true });
                            break;
                          case 'admin':
                            window.localStorage.setItem('role', 'admin');
                            enqueueSnackbar('Login Successfull', { variant: 'success' });
                            navigate('/admin', { replace: true });
                            break;
                          case 'leadAdmin':
                            window.localStorage.setItem('role', 'leadAdmin');
                            enqueueSnackbar('Login Successfull', { variant: 'success' });
                            navigate('/leadadmin', { replace: true });
                            break;
                          case '360Recruiter':
                            window.localStorage.setItem('role', '360Recruiter');
                            enqueueSnackbar('Login Successfull', { variant: 'success' });
                            navigate('/recruiter', { replace: true });
                            break;
                          case 'resourcer':
                            window.localStorage.setItem('role', 'resourcer');
                            enqueueSnackbar('Login Successfull', { variant: 'success' });
                            navigate('/resourcing', { replace: true });
                            break;
                          case 'hrm':
                            window.localStorage.setItem('role', 'hrm');
                            enqueueSnackbar('Login Successfull', { variant: 'success' });
                            navigate('/hrm', { replace: true });
                            break;
                          case 'QAResourcing':
                            window.localStorage.setItem('role', 'QAResourcing');
                            enqueueSnackbar('Login Successfull', { variant: 'success' });
                            navigate('/rqa', { replace: true });
                            break;
                          case 'bdExpert':
                            window.localStorage.setItem('role', 'bdExpert');
                            enqueueSnackbar('Login Successfull', { variant: 'success' });
                            navigate('/bdm', { replace: true });
                            break;
                          default:
                            enqueueSnackbar('You have no Roles assigned by admin');
                            break;
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          id="role"
                          name="role"
                          {...params}
                          placeholder="Select the role"
                          {...getFieldProps('role')}
                          error={Boolean(touched.role && errors.role)}
                          helperText={touched.role && errors.role}
                          sx={{
                            borderRadius: '0.3rem',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#00C1FE',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#00C1FE',
                              },
                            },
                            '& .MuiInputBase-root': {
                              '&.Mui-focused': {
                                boxShadow: '0 0 0 1px #00C1FE',
                              },
                            },
                          }}
                        />
                      )}
                    />
                  ) : null}
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: '#00C1FE',
                          '&.Mui-checked': {
                            color: '#00C1FE',
                          },
                        }}
                      />
                    }
                    label="Remember me"
                  />
                  <Link variant="subtitle2" underline="hover" href="forgetPassword">
                    Forgot password?
                  </Link>
                </Stack>

                <LoadingButton
                  fullWidth
                  loading={isSubmitting}
                  loadingIndicator="Logging in..."
                  className="text-white border-[#0fa3de] bg-[#0fa3de]  hover:bg-[#0fa3de] h-[52px] text-xl rounded-lg"
                  onClick={handleSubmit}
                >
                  Login
                </LoadingButton>
              </Form>
            );
          }}
        </Formik>
        <Typography variant="h6" sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              Don’t have an account? &nbsp;
              <Link 
                variant="subtitle"
                href="register"
                sx={{
                  color: 'darkblue',
                }}
              >
                Register
              </Link>
            </Typography>
      </div>
    </>
  );
};

export default LoginForm;
