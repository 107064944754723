import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from 'src/utils/formatNumber';
// components
import { useChart } from 'src/components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

BarChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

export default function BarChart({ title, subheader, chartColors, chartData, ...other }) {
  const theme = useTheme();

  // Prepare the data for the bar chart
  const chartLabels = chartData?.map((i) => i?.label); // x-axis labels
  const chartSeries = [
    {
      name: 'Total Sent', // You can change this name as per your data
      data: chartData.map((i) => i?.value), // y-axis values
    },
  ];

  const chartOptions = useChart({
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'rounded',
        columnWidth: '60%', // Adjust the width of the bars
      },
    },
    xaxis: {
      categories: chartLabels, // x-axis labels
    },
    yaxis: {
      title: {
        text: 'Total Sent', // You can adjust this title if needed
      },
    },
    grid: {
      borderColor: theme.palette.divider,
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <StyledChartWrapper dir="ltr">
        <ReactApexChart
          type="bar" // Switch to 'bar' type
          series={chartSeries}
          options={chartOptions}
          height={280} // You can adjust this as needed
        />
      </StyledChartWrapper>
    </Card>
  );
}
