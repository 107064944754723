import { Icon } from '@iconify/react';
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import PieChart from 'src/components/chart/PieChart';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';
import { HighchartsBarChart } from '../../campaigns/components';
import { BouncedEmails, OpenedEmailsTables, SpamEmailsTable, UnsubEmailsTable } from '.';
import BarChart from 'src/components/chart/BarChart';
import MultiDatePicker from 'src/components/MultiDatePicker';

export default function CampaignReport({ campaign, onBack }) {

  const sectors = [
    { id: null, label: 'All' },
    { id: 1, label: 'Direct' },
    { id: 2, label: 'Staffing and Recruiting' },
  ];
  const [sendingAnalytics, setSendingAnalytics] = useState();
  const [industryAnalytics, setIndustryAnalytics] = useState();
  const [sectorStats, setSectorStats] = useState();
  const [users, setUsers] = useState([]);

  const [emailNotificationAnalytics, setEmailNotificationAnalytics] = useState();
  const [formattedDates, setFormattedDates] = useState([]);
  const [selectedSector, setSelectedSector] = useState(sectors[0]);

  const handleSectorChange = (event, newValue) => {
    setSelectedSector(newValue);
  };

  const getSendingStats = useCallback(() => {
    try {
      Get(
        {
          sector_id: selectedSector.id,
          from_date: formattedDates[0],
          to_date: formattedDates[1],
        },
        API_URLS.getRegionWiseSendingStats,
        (resp) => {
          //   enqueueSnackbar('Sending stats fetched successfully', { variant: 'success' });

          setSendingAnalytics(resp?.data?.regions);
        },
        (error) => {
          enqueueSnackbar('Error fetching sending stats', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [campaign, formattedDates, selectedSector]);
  
  const getSectorStats = useCallback(() => {
    try {
      Get(
        {
          sector_id: selectedSector.id,
          from_date: formattedDates[0],
          to_date: formattedDates[1],
        },
        API_URLS.getSectorWiseSendingStats,
        (resp) => {
          //   enqueueSnackbar('Sending stats fetched successfully', { variant: 'success' });

          setSectorStats(resp?.data?.sectors);
        },
        (error) => {
          enqueueSnackbar('Error fetching sending stats', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [campaign, formattedDates, selectedSector]);

  const getIndustrySendingStats = useCallback(() => {
    try {
      Get(
        {
          sector_id: selectedSector.id,
          from_date: formattedDates[0],
          to_date: formattedDates[1],
        },
        API_URLS.getIndustryWiseSendingStats,
        (resp) => {
          //   enqueueSnackbar('Sending stats fetched successfully', { variant: 'success' });

          setIndustryAnalytics(resp?.data?.industries);
        },
        (error) => {
          enqueueSnackbar('Error fetching sending stats', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [campaign, formattedDates, selectedSector]);

  useEffect(() => {
    getSendingStats();
    getIndustrySendingStats();
    getSectorStats();
  }, [getSendingStats, getIndustrySendingStats, getSectorStats]);

  const getEmailNotificationStats = useCallback(() => {
    try {
      Get(
        {
          sector_id: selectedSector.id,
          from_date: formattedDates[0],
          to_date: formattedDates[1],
        },
        API_URLS.getAmazonSentStats,
        (resp) => {
          // enqueueSnackbar('Email notification stats fetched successfully', { variant: 'success' });
          setEmailNotificationAnalytics(resp?.data);
        },
        (error) => {
          // enqueueSnackbar('Error fetching email notification stats', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }, [campaign, formattedDates, selectedSector]);

  const getUsers=useCallback(()=>{
    try {
      Get(
        {
          sector_id: selectedSector.id,
          from_date: formattedDates[0],
          to_date: formattedDates[1],
        },
        API_URLS.getUniqueUserIds,
        resp=>{
          setUsers(resp.data.users)
        },
        error=>{
          enqueueSnackbar('Error fetching users', { variant: 'error' });
        }
      )
    } catch (error) {
      
    }
  },[])
  
  useEffect(() => {
    getEmailNotificationStats();
  }, [getEmailNotificationStats]);

  
  const barChartData = [
    {
      name: 'Event Metrics',
      data: [
        parseInt(emailNotificationAnalytics?.unique_jobs, 10) || 0,
        parseInt(emailNotificationAnalytics?.emails_sent, 10) || 0,
        parseInt(emailNotificationAnalytics?.email_delivered, 10) || 0,
        parseInt(emailNotificationAnalytics?.email_opened, 10) || 0,
        parseInt(emailNotificationAnalytics?.email_clicked, 10) || 0,
        parseInt(emailNotificationAnalytics?.email_bounced, 10) || 0,
        parseInt(emailNotificationAnalytics?.email_spammed, 10) || 0,
        parseInt(emailNotificationAnalytics?.emails_unopened, 10) || 0,
        parseInt(emailNotificationAnalytics?.email_complaints, 10) || 0,
      ],
      colors: ['#999999', '#89C885', '#5AA2CB', '#4d4d4d', '#CE5C56', '#FFD700', '#FFA500', '#EBA500',"#ABA500"],
      // colorByPoint: true, // Allows individual bars to have different colors
    },
  ];

  const calculatePercentages = (analytics) => {
    if (!analytics) return {};

    const totalDeliveries = parseInt(analytics.email_delivered, 10) || 0;
    const totalOpens = parseInt(analytics.email_opened, 10) || 0;
    const totalClicks = parseInt(analytics.email_clicked, 10) || 0;
    const totalBounces = parseInt(analytics.email_bounced, 10) || 0;
    const totalSpams = parseInt(analytics.email_spammed, 10) || 0;
    const totalUnopened = parseInt(analytics.emails_unopened, 10) || 0;
    const totalComplaints = parseInt(analytics.email_complaints, 10) || 0;
    const totalSent = parseInt(analytics.emails_sent, 10) || 0;
    const totalJobs = parseInt(analytics.unique_jobs, 10) || 0;

    // Avoid division by zero
    const safePercentage = (value) => {
      return totalDeliveries === 0 ? '0.00' : ((value / totalDeliveries) * 100).toFixed(2);
    };

    return {
      openRate: safePercentage(totalOpens),
      clickRate: safePercentage(totalClicks),
      bounceRate: safePercentage(totalBounces),
      spamRate: safePercentage(totalSpams),
      unopenedRate: safePercentage(totalUnopened),
      complaintRate: safePercentage(totalComplaints),
      sentRate: safePercentage(totalSent),
      jobRate: safePercentage(totalJobs),
    };
  };

  const percentages = calculatePercentages(emailNotificationAnalytics);

  return (
    <>
      {/* <div className="flex items-center justify-between mb-4">
        <Icon
          icon="ic:round-arrow-back"
          style={{ fontSize: '30px', color: '#00C1FE', cursor: 'pointer' }}
          onClick={() => onBack()}
        />
      </div> */}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}></Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box
            display="flex"
            justifyContent="flex-end"
            gap={1}
            sx={{ marginBottom: '1rem', width: '95%', margin: 'auto' }}
          >
            <MultiDatePicker
              height="55px"
              onChange={(dateRange) => {
                setFormattedDates(dateRange);
              }}
            />
            <Autocomplete
              options={sectors}
              fullWidth
              getOptionLabel={(option) => option.label}
              value={selectedSector}
              onChange={handleSectorChange}
              renderInput={(params) => <TextField {...params} label="Select Sector" variant="outlined" />}
              style={{ marginBottom: '20px' }}
            />
          </Box>
        </Grid>
        {/*
        <Grid item xs={12} sm={6} md={3}>
          <div>
            <label
              htmlFor="toDate"
              style={{
                display: 'block',
                fontSize: '18px',
                marginBottom: '8px',
                color: '#0ec4fe',
                marginLeft: '10px',
              }}
            >
              To Date
            </label>
            <TextField
              id="toDate"
              type="date"
              value={toDate}
              fullWidth
              sx={{
                fontSize: '18px',
                borderRadius: '10px',
                color: '#0ec4fe',
                marginLeft: '10px',
                border: '3px solid #0ec4fe',
                '&:hover': {
                  color: '#0ec4fe',
                  boxShadow: 'none',
                  backgroundColor: 'transparent',
                  border: '3px solid #0ec4fe !important',
                },
              }}
              size="medium"
              onChange={(e) => setToDate(new Date(e.target.value).toISOString().split('T')[0])}
            />
          </div>
        </Grid> */}
      </Grid>
      <br />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Box sx={{ marginLeft: '10px' }}>
            {sendingAnalytics?.length > 0 ? (
              <PieChart
                title="Region Analytics"
                chartData={sendingAnalytics.map((region) => ({
                  label: region.region, // Dynamic region label
                  value: Number(region.total_sent) || 0.5, // Convert total_sent to a number, default to 0.5 if zero
                }))}
                chartColors={
                  sendingAnalytics.every((region) => Number(region.total_sent) === 0)
                    ? ['#D3D3D3', '#D3D3D3', '#D3D3D3', '#D3D3D3'] // Grey if all values are zero
                    : sendingAnalytics.map(
                        (_, index) => ['#579FC8', '#CE5C56', '#4CAF50', '#FF9800', '#9C27B0', '#2196F3'][index % 6]
                      ) // Dynamic color selection
                }
              />
            ) : (
              <PieChart
                title="Default"
                chartData={[
                  { label: 'Emails sent', value: 0 },
                  { label: 'Emails not sent', value: 0 },
                ]}
                chartColors={['#D3D3D3', '#D3D3D3']} // Grey if no data
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box sx={{ marginLeft: '10px' }}>
            {sectorStats?.length > 0 ? (
              <PieChart
                title="Sector Analytics"
                chartData={sectorStats.map((sector) => ({
                  label: sector.sector, // Dynamic sector label
                  value: Number(sector.total_sent) || 0.5, // Convert total_sent to a number, default to 0.5 if zero
                }))}
                chartColors={
                  sectorStats.every((sector) => Number(sector.total_sent) === 0)
                    ? ['#D3D3D3', '#D3D3D3', '#D3D3D3', '#D3D3D3'] // Grey if all values are zero
                    : sectorStats.map(
                        (_, index) => ['#579FC8', '#CE5C56', '#4CAF50', '#FF9800', '#9C27B0', '#2196F3'][index % 6]
                      ) // Dynamic color selection
                }
              />
            ) : (
              <PieChart
                title="Default"
                chartData={[
                  { label: 'Emails sent', value: 0 },
                  { label: 'Emails not sent', value: 0 },
                ]}
                chartColors={['#D3D3D3', '#D3D3D3']} // Grey if no data
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <HighchartsBarChart
            title="Email Sending Metrics"
            subheader="Event Metrics by Type"
            chartLabels={[
              'Jobs',
              'Sent',
              'Recipents',
              'Opens',
              'Clicks',
              'Bounces',
              'Spams',
              'Un-Opened',
              'Complaints',
            ]}
            chartData={barChartData}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box sx={{ marginLeft: '10px' }}>
            {industryAnalytics?.length > 0 ? (
              <BarChart
                title="Industry Analytics"
                chartData={(() => {
                  const sortedIndustries = [...industryAnalytics].sort(
                    (a, b) => Number(b.total_sent) - Number(a.total_sent)
                  );

                  const topIndustries = sortedIndustries.slice(0, 5); // Top 10 industries
                  const remainingIndustries = sortedIndustries.slice(5); // Remaining industries

                  const remainingTotal = remainingIndustries.reduce(
                    (acc, industry) => acc + Number(industry.total_sent),
                    0
                  );

                  // Combine top industries with the remaining total as "Others"
                  const finalData = [
                    ...topIndustries.map((industry) => ({
                      label: industry.industry,
                      value: Number(industry.total_sent) || 0,
                    })),
                    {
                      label: 'Others',
                      value: remainingTotal || 0,
                    },
                  ];

                  return finalData;
                })()}
                chartColors={
                  industryAnalytics.every((industry) => Number(industry.total_sent) === 0)
                    ? ['#D3D3D3'] // Grey if all values are zero
                    : industryAnalytics.map(
                        (_, index) => ['#579FC8', '#CE5C56', '#4CAF50', '#FF9800', '#9C27B0', '#2196F3'][index % 6]
                      )
                }
              />
            ) : (
              <BarChart
                title="Default"
                chartData={[
                  { label: 'Emails sent', value: 0 },
                  { label: 'Emails not sent', value: 0 },
                ]}
                chartColors={['#D3D3D3']} // Grey if no data
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={6} md={6}>
          <Box
            sx={{
              marginLeft: '10px',
              backgroundColor: '#F6F9FC',
              padding: '16px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              height: '200px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Typography variant="body1">
              <span
                style={{
                  backgroundColor: '#89C885',
                  color: 'white',
                  padding: '8px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '100px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {percentages?.openRate || '0.00'}%
              </span>
              &nbsp; &nbsp; <span style={{ fontSize: '20px' }}>Opened</span>
              &nbsp; &nbsp;
              <span
                style={{
                  backgroundColor: '#FFFFFF',
                  color: '#9fadc6',
                  border: '1px solid #D4DAE6',
                  borderRadius: '5px',
                  padding: '3px',
                  fontSize: '12px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '120px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {' '}
                {emailNotificationAnalytics?.email_opened ? emailNotificationAnalytics?.email_opened : 0} Unique opens{' '}
              </span>
            </Typography>
            <Typography variant="body1">
              <span
                style={{
                  backgroundColor: '#F5CA0B',
                  color: 'white',
                  padding: '8px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '100px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {emailNotificationAnalytics?.emails_unopened || '0'}
              </span>
              &nbsp; &nbsp; <span style={{ fontSize: '20px' }}>Not Opened</span>
            </Typography>
            <Typography variant="body1">
              <span
                style={{
                  backgroundColor: '#39A0CC',
                  color: 'white',
                  padding: '8px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '100px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {' '}
                {percentages?.clickRate || '0.00'}%{' '}
              </span>
              &nbsp; &nbsp; <span style={{ fontSize: '20px' }}>Clicked a link</span>
              &nbsp; &nbsp;
              <span
                style={{
                  backgroundColor: '#FFFFFF',
                  color: '#9fadc6',
                  border: '1px solid #D4DAE6',
                  borderRadius: '5px',
                  padding: '3px',
                  fontSize: '12px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '120px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {' '}
                {emailNotificationAnalytics?.email_clicked ? emailNotificationAnalytics?.email_clicked : 0} Unique
                clicks{' '}
              </span>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box
            sx={{
              marginLeft: '10px',
              backgroundColor: '#F6F9FC',
              padding: '16px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              height: '200px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Typography variant="body1">
              <span
                style={{
                  backgroundColor: '#4D4D4D',
                  color: 'white',
                  padding: '8px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '100px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {' '}
                {percentages?.bounceRate || '0.00'}%
              </span>{' '}
              &nbsp; &nbsp; <span style={{ fontSize: '20px' }}>Bounced</span>
              &nbsp; &nbsp;
              <span
                style={{
                  backgroundColor: '#FFFFFF',
                  color: '#9fadc6',
                  border: '1px solid #D4DAE6',
                  borderRadius: '5px',
                  padding: '3px',
                  fontSize: '12px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '120px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {' '}
                {emailNotificationAnalytics?.email_bounced ? emailNotificationAnalytics?.email_bounced : 0} Bounced{' '}
              </span>
            </Typography>
            <Typography variant="body1">
              <span
                style={{
                  backgroundColor: '#CE5C56',
                  color: 'white',
                  padding: '8px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '100px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {percentages?.spamRate || '0.00'}%
              </span>
              &nbsp; &nbsp; <span style={{ fontSize: '20px' }}>Marked as Spam</span>
              &nbsp; &nbsp;
              <span
                style={{
                  backgroundColor: '#FFFFFF',
                  color: '#9fadc6',
                  border: '1px solid #D4DAE6',
                  borderRadius: '5px',
                  padding: '3px',
                  fontSize: '12px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '120px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {' '}
                {emailNotificationAnalytics?.email_spammed ? emailNotificationAnalytics?.email_spammed : 0} marked as
                spam{' '}
              </span>
            </Typography>
            <Typography variant="body1">
              <span
                style={{
                  backgroundColor: '#FFA500',
                  color: 'white',
                  padding: '8px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '100px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {percentages?.complaintRate || '0.00'}%
              </span>
              &nbsp; &nbsp; <span style={{ fontSize: '20px' }}>Complaints</span>
              &nbsp; &nbsp;
              <span
                style={{
                  backgroundColor: '#FFFFFF',
                  color: '#9fadc6',
                  border: '1px solid #D4DAE6',
                  borderRadius: '5px',
                  padding: '3px',
                  fontSize: '12px',
                  display: 'inline-block', // Ensure the width is respected
                  width: '120px',
                  textAlign: 'center', // Center the text inside the span
                }}
              >
                {' '}
                {emailNotificationAnalytics?.email_complaints ? emailNotificationAnalytics?.email_complaints : 0}{' '}
                complaints{' '}
              </span>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <br />
      <br />
      {/* <EmailBasedReportingGraphs emailDelivered={emailDelivered} campaignBasedData={campaignBasedData} /> */}
      <br />
      <BouncedEmails campaign={campaign} fromDate={formattedDates[0]} toDate={formattedDates[1]} />
      <br />
      <OpenedEmailsTables campaign={campaign} fromDate={formattedDates[0]} toDate={formattedDates[1]} />
      <br />
      <SpamEmailsTable campaign={campaign} fromDate={formattedDates[0]} toDate={formattedDates[1]} />
      <br />
      <UnsubEmailsTable campaign={campaign} fromDate={formattedDates[0]} toDate={formattedDates[1]} />
    </>
  );
}
