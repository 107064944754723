import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      {...props}
      timeout={{ enter: 1000, exit: 500 }}
      easing={{ enter: 'cubic-bezier(0.4, 0, 0.2, 1)', exit: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
    />
  );
});

function ResetPassword({ open, onClose, updateUser, onUpdate }) {
  const { enqueueSnackbar } = useSnackbar();

  const initialValue = () => ({
    password: '',
    confirmPassword: '',
  });

  // validate both passowrds
  const basicValidationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const handleSubmit = async (values) => {
    try {
      onUpdate(values);
    } catch (error) {
      enqueueSnackbar('Error changing password', { variant: 'error' });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={false}
        disableBackdropClick
        disableEscapeKeyDown
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            ml: 'auto',
            mt: 'auto',
            mr: '2rem',
            width: '900px',
            height: '90%',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change this value to adjust the opacity
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#F4F9FD',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4" className="ml-8">
              Reset Profile
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <br />
          <Formik
            enableReinitialize
            initialValues={initialValue(updateUser)}
            validationSchema={basicValidationSchema}
            onSubmit={(values,actions)=>{
              handleSubmit(values);
            }}
          >
            {({ values, errors, touched, setFieldValue, handleSubmit, getFieldProps, isSubmitting }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label htmlFor="password" style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                      New Password
                    </label>
                    <TextField
                      id="password"
                      fullWidth
                      name="password"
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      {...getFieldProps('password')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label htmlFor="confirmPassword" style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                      Confirm Password
                    </label>

                    <TextField
                      id="confirmPassword"
                      fullWidth
                      name="confirmPassword"
                      error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                      helperText={touched.confirmPassword && errors.confirmPassword}
                      {...getFieldProps('confirmPassword')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '0.3rem',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#00C1FE',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#00C1FE',
                          },
                        },
                        '& .MuiInputBase-root': {
                          '&.Mui-focused': {
                            boxShadow: '0 0 0 1px #00C1FE',
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Box display="flex" alignItems="flex-end" justifyContent="flex-end" sx={{ marginTop: '2rem' }} gap={2}>
                  <button
                    style={{
                      backgroundColor: '#F4F9FD',
                      color: '#00C1FE',
                      // border: '1px solid #00C1FE',
                      borderRadius: '0.3rem',
                    }}
                    onClick={() => onClose()}
                    size="small"
                  >
                    Cancel
                  </button>
                  <LoadingButton
                    loading={isSubmitting}
                    onClick={() => {
                      handleSubmit();
                      onClose();
                    }}
                    className="px-2 py-[9px] mt-4 text-white bg-[#00C1FE] hover:bg-[#00C1FE] text-sm rounded-md "
                  >
                    Override Password
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ResetPassword;
