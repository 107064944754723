import React, { useCallback, useEffect, useState } from 'react';
import { Edit, SyncTwoTone } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { useSnackbar } from 'notistack';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import axios from 'axios';

function EmailMarketingLeads({ from_date, to_date, country_id, sector_id }) {
  const [emails, setEmails] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sorting, setSorting] = useState([]);
  const [isError, setIsError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
  });

  const getMarketingEmails = useCallback(() => {
    setIsLoading(true);
    Get(
      {
        startDate: from_date,
        endDate: to_date,
        country_id: country_id,
        sector_id: sector_id,
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
      API_URLS.getMarketingEmailsWitPersonName,
      (resp) => {
        setIsLoading(false);
        setEmails(resp?.data?.emails);
        setTotalCount(resp?.data?.total);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }, [from_date, to_date, country_id, sector_id, pagination.page, pagination.pageSize]);

  useEffect(() => {
    getMarketingEmails();
  }, [getMarketingEmails]);

  // Define columns for the table
  const columns = [
    {
      accessorKey: 'person.first_name', // Map first_name from the Persons model
      header: 'First Name',
    },
    {
      accessorKey: 'email_id', // Map email_id
      header: 'Email ID',
    },
    // {
    //   accessorKey: 'updatedAt', // Map updatedAt
    //   header: 'Last Updated',
    //   Cell: ({ cell }) => new Date(cell.getValue()), // Format date
    // },
  ];

  const handleDownloadEmailData = async () => {
    try {
      const response = await axios.get(API_URLS.downloadMarketingEmailsWitPersonName, {
        params: {
          startDate: from_date, // Example start date
          endDate: to_date, // Example end date
          sector_id: sector_id,
        },
        responseType: 'blob', // To handle binary data like Excel files
      });

      // Create a URL for the blob response
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element to download the file
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'marketing_emails.xlsx'); // Specify the download file name
      document.body.appendChild(link);
      link.click(); // Simulate a click to trigger the download

      // Clean up by removing the link element
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading email data:', error);
      // Optionally display an error message to the user
    }
  };
  

  return (
    <>
      <Button
        variant="contained"
        sx={{
          //   width: '40px',
          height: '40px',
          //   borderRadius: '50%',
          backgroundColor: '#00C1FE',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: 'unset',
          '&:hover': {
            backgroundColor: '#00C1FE',
            color: 'white',
          },
        }}
        onClick={() => handleDownloadEmailData()}
      >
        Download
      </Button>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120, // Adjust the size as needed
          },
        }}
        columns={columns}
        data={emails}
        enableColumnFilters={false}
        defaultColumn={{
          size: 50,
        }}
        manualPagination
        rowCount={totalCount}
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        // muiTableBodyRowProps={{ hover: false }}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          sorting,
        }}
        muiTableProps={{
          sx: {
            borderRadius: '30px',
          },
        }}
        positionToolbarAlertBanner="bottom"
        actionsColumnIndex={-1} // Set to 0 if placing actions at the start
        enableRowActions
        renderRowActions={({ row }) => (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Edit onClick={() => {}} sx={{ cursor: 'pointer' }} />
            {row.original.status === 'Expired' && (
              <SyncTwoTone
                sx={{
                  cursor: 'pointer',
                  color: 'green',
                  marginLeft: '10px',
                  fontSize: '30px',
                }}
                onClick={() => {}}
              />
            )}
          </Box>
        )}
      />
    </>
  );
}

export default EmailMarketingLeads;
