import { Refresh } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';

function RefreshButton({onClick}) {
    return (
      <div>
        <Button
          style={{
            outline: '1px solid rgba(0, 0, 0, 0.12)',
            backdropFilter: 'blur(2px)',
            width: '150px',
            height: '40px',
            backgroundColor: '#00C1FE',
            color: 'white',
            border: '1px solid #00C1FE',
            marginRight: '5px',
            '&:hover': {
              backgroundColor: '#00C1FE',
              color: 'white',
            },
          }}
          size="small"
          onClick={onClick}
        >
          <Refresh style={{ marginRight: '5px' }} />
        </Button>
      </div>
    );
}

export default RefreshButton;