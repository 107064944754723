import React, { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default function HighchartsBarChart({ title, subheader, chartData, chartLabels }) {

  const options = {
    chart: {
      type: 'bar',
      height: '440px',
      backgroundColor: 'white',
      style: { fontFamily: 'Arial, sans-serif' },
      plotBorderWidth: 1,
      plotShadow: true,
    },
    title: { text: title },
    subtitle: { text: subheader },
    xAxis: { categories: chartLabels, title: { text: null } },
    yAxis: {
      min: 0,
      title: { text: 'Values', align: 'high' },
      labels: { overflow: 'justify' },
    },
    tooltip: { valueSuffix: ' units' },
    plotOptions: {
      bar: {
        dataLabels: { enabled: true },
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    credits: { enabled: false },
    series: chartData?.map((series) => ({
      ...series,
      data: series?.data?.map((value, index) => ({
        y: value,
        color: series.colors && series.colors[index] ? series.colors[index] : undefined,
      })),
    })),
  };

  return (
    <div
      style={{
        backgroundColor: '#f9f9f9',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        borderRadius: '8px',
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
