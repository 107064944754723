import { Autocomplete, Box, TextField, Tooltip, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Get } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';
import { CSVLink } from 'react-csv'; // Import CSVLink from react-csv
import { Icon } from '@iconify/react';

function BouncedEmails({ fromDate, toDate, sector_id }) {
  // table states
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bouncedEmails, setBouncedEmails] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const columns = useMemo(
    () => [
      {
        accessorKey: 'person.full_name',
        header: 'Name',
        minWidth: 170,
      },
      {
        accessorKey: 'email_id',
        header: 'Email',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'email',
        header: 'Status',
        enableClickToCopy: true,
        minWidth: 170,
        Cell: ({ cell }) => (
          <Box component="span">
            <>
              <Typography
                variant="body2"
                style={{
                  color: 'white',
                  backgroundColor: '#4D4D4D',
                  padding: '5px',
                  borderRadius: '5px',
                  width: '100px',
                  textAlign: 'center',
                }}
              >
                Bounced
              </Typography>
            </>
          </Box>
        ),
      },
      {
        accessorKey: 'user.first_name',
        header: 'Lead Expert',
        minWidth: 170,
      },
      // {
      //   accessorKey: 'createdAt',
      //   header: 'Date',
      //   minWidth: 170,
      //   Cell: ({ cell }) => {
      //     const date = new Date(cell.getValue());
      //     return <Typography variant="body2">{formatDistanceToNow(date, { addSuffix: true })}</Typography>;
      //   },
      // },
    ],
    [] // Recalculate when counters change
  );

  const getRecentBouncedEmails = useCallback(() => {
    try {
      setIsLoading(true);
      Get(
        {
          sector_id: sector_id,
          fromDate: fromDate,
          toDate: toDate,
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
          user_id: selectedUser?.id,
        },
        API_URLS.getRecentBouncedEmails,
        (resp) => {
          setBouncedEmails(resp?.data.data);
          setTotalCount(resp?.data.count);
          setUsers(resp?.data.users);
          setIsLoading(false);
        },
        (error) => {
          setIsError(true);
          setIsLoading(false);
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
      setIsError(true);
      setIsLoading(false);
    }
  }, [fromDate, pagination.pageIndex, pagination.pageSize, sector_id, toDate, selectedUser]);

  useEffect(() => {
    getRecentBouncedEmails();
  }, [getRecentBouncedEmails]);

  // // Convert data to CSV
  const csvData = bouncedEmails?.map((email) => ({
    Name: email?.person?.full_name,
    Email: email?.email,
    Status: 'Bounced',
    Addedby: email?.user?.first_name + ' ' + email?.user?.last_name,
    Date: new Date(email?.createdAt).toLocaleDateString(),
  }));
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ marginLeft: '1rem' }} variant="h4">
          Bounced Emails
        </Typography>
        <CSVLink data={csvData} style={{ marginLeft: '10px' }} filename="bounced-emails.csv">
          <Tooltip title="Download" arrow>
            <Icon icon="ic:outline-download" style={{ fontSize: '25px' }} />
          </Tooltip>
        </CSVLink>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, margin:'20px' }}>
        <TextField
          id="search"
          label="Search"
          variant="outlined"
          style={{ width: 300 }}
          onChange={(e) => {
            setGlobalFilter(e.target.value);
          }}
        />
        <Autocomplete
          id="users"
          options={users}
          getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="User" variant="outlined" />}
          onChange={(e, value) => {
            setSelectedUser(value);
          }}
        />
      </Box>

      <MaterialReactTable
        columns={columns}
        data={bouncedEmails}
        rowCount={totalCount}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableTopToolbar={false}
        defaultColumn={{
          size: 50,
        }}
        manualPagination
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        muiTableBodyRowProps={{ hover: false }}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          sorting,
        }}
        muiTableProps={{
          sx: {
            borderRadius: '30px',
          },
        }}
      />
    </>
  );
}

export default BouncedEmails;
