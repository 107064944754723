import { Autocomplete, Avatar, Box, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Post } from 'src/actions/API/apiActions';
import { API_URLS } from 'src/constants/apiURLs';
import { useSnackbar } from 'notistack';
import { roles } from 'src/constants/constants';
import MaterialReactTable from 'material-react-table';
import { AccountCircleOutlined, CircleRounded , LockReset, NoAccountsOutlined } from '@mui/icons-material';
import { serverURLForImages } from 'src/constants/serverURLs';
import { UpdateUserDialog } from '.';
import ResetPassword from './PasswordReset';

function Users({ role }) {
  const columns = [
    {
      accessorKey: 'full_name',
      header: 'Name',
      size: 40,
      headerAlign: 'left',
    },
    {
      accessorKey: 'email',
      header: 'Email',
      size: 40,
      headerAlign: 'left',
    },
    {
      accessorKey: 'is_verified',
      header: 'Verification',
      size: 40,
      headerAlign: 'left',
    },
    {
      accessorKey: 'working_mode',
      header: 'Working Mode',
      size: 40,
      headerAlign: 'left',
    },
    {
      accessorKey: 'is_approved',
      header: 'Approval',
      size: 40,
      headerAlign: 'left',
    },
  ];

  const [users, setUsers] = useState([]);
  const [userStatus, setUserStatus] = useState('active');
  const [userRole, setUserRole] = useState(role === 'hrm' ? 'hrm' : 'admin');
  const userRoles = role === 'hrm' ? roles?.filter((role) => role?.id !== 'admin') : roles;
  // table states
  const [totalCount, setTotalCount] = useState(0);
  const [searchString, setSearchString] = useState('');
  const [loadingData, setLoadingData] = useState(false);
  const [title, setTitle] = useState('All Users');
  const { enqueueSnackbar } = useSnackbar();
  const [openUserProfileDialog, setOpenUserProfileDialog] = useState(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [userData, setUserData] = useState(null);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isError, setIsError] = useState(false);

  const getAllUsers = useCallback(() => {
    setLoadingData(true);
    Post(
      {
        userStatus: userStatus,
        searchString: searchString,
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageIndex,
        userRole: userRole,
      },
      API_URLS.getAllUsers,
      (resp) => {
        setLoadingData(false);
        setUsers(resp?.users);
        setTotalCount(resp?.totalCount);
      },
      (error) => {
        setIsError(false);
        setLoadingData(false);
      }
    );
  }, [searchString, pagination.pageSize, pagination.pageIndex, userStatus, userRole]);

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  // Update User
  const handleUpdateUser = (values, actions) => {
    try {
      Post(
        values,
        API_URLS.updateUserAndGiveFeatureAcess,
        (resp) => {
          actions.setSubmitting(false);
          enqueueSnackbar('User Updated Successfully', { variant: 'success' });
          getAllUsers();
          //   setOpenUserProfileDialog(false);
        },
        (error) => {
          enqueueSnackbar('User not Updated', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  let newTitle = [
    {
      id: 'active',
      label: 'All users',
    },
    {
      id: 'approved',
      label: 'Approved users',
    },
    {
      id: 'pending',
      label: 'Pending users',
    },
  ];

  const handleDeactivateAccount = (userId) => {
    Post(
      {
        userId: userId,
      },
      API_URLS.deactivateAccount,
      (resp) => {
        enqueueSnackbar('Account Deactivated Successfully', { variant: 'success' });
        getAllUsers();
      },
      (error) => {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    );
  };
  const handleActivateAccount = (userId) => {
    Post(
      {
        userId: userId,
      },
      API_URLS.approveAccount,
      (resp) => {
        enqueueSnackbar('Account Approved Successfully', { variant: 'success' });
        getAllUsers();
      },
      (error) => {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    );
  };

  const handleChangePassword = (values, actions) => {
    values.userId = userData.id;
    try {
      Post(
        values,
        API_URLS.changePassword,
        (resp) => {
          actions.setSubmitting(false);
          enqueueSnackbar('Password Changed Successfully', { variant: 'success' });
          setOpenResetPassword(false);
        },
        (error) => {
          actions.setSubmitting(false);
          enqueueSnackbar('Password not Changed', { variant: 'error' });
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return (
    <>
      <Grid container spacing={3} mb={2} mt={1}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Autocomplete
            fullWidth
            options={userRoles}
            getOptionLabel={(option) => option?.role}
            onChange={(event, newTitle) => {
              setUserRole(newTitle?.id);
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                placeholder="Slect Role"
                sx={{
                  marginLeft: '1rem',
                  backgroundColor: 'white',
                  borderRadius: '0.3rem',
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#00C1FE',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#00C1FE',
                    },
                  },
                  '& .MuiInputBase-root': {
                    '&.Mui-focused': {
                      boxShadow: '0 0 0 1px #00C1FE',
                    },
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Autocomplete
            fullWidth
            options={newTitle}
            getOptionLabel={(option) => option?.label}
            onChange={(event, newStatus) => {
              if (newStatus !== null || newStatus !== '' || typeof newStatus !== 'undefined') {
                setUserStatus(newStatus?.id);
                if (newStatus?.id === 'active') {
                  setTitle('All Users');
                } else if (newStatus?.id === 'approved') {
                  setTitle('Approved Users');
                } else if (newStatus?.id === 'pending') {
                  setTitle('Pending Users');
                }
              } else if (newStatus === null) {
                setUserStatus('active');
                setTitle(title);
              } else {
                setUserStatus(userStatus);
                setTitle(title);
              }
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                placeholder="Select user"
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '0.3rem',
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#00C1FE',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#00C1FE',
                    },
                  },
                  '& .MuiInputBase-root': {
                    '&.Mui-focused': {
                      boxShadow: '0 0 0 1px #00C1FE',
                    },
                  },
                }}
              />
            )}
          />
        </Grid>
      </Grid>

      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        enableRowActions
        columns={columns}
        data={users}
        manualPagination
        rowCount={totalCount}
        positionToolbarAlertBanner="bottom"
        onPaginationChange={setPagination}
        manualFiltering
        onGlobalFilterChange={setSearchString}
        state={{
          pagination,
          isLoading: loadingData,
        }}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: 'error',
                children: 'Error Loading Data',
              }
            : undefined
        }
        renderRowActions={({ row, table }) => (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {row?.original.is_approved === 'approved' && row.original.role !== 'admin' ? (
                <IconButton onClick={() => handleDeactivateAccount(row?.original.id)}>
                  <Tooltip title="Deactivate Account">
                    <AccountCircleOutlined />
                  </Tooltip>
                </IconButton>
              ) : row?.original.is_approved === 'pending' && row.original.role !== 'admin' ? (
                <IconButton onClick={() => handleActivateAccount(row.original.id)}>
                  <Tooltip title="Activate Account">
                    <NoAccountsOutlined />
                  </Tooltip>
                </IconButton>
              ) : (
                <h6>Admin</h6>
              )}
              {row.original.is_loggedin ? (
                <>
                  <IconButton>
                    <Tooltip title="User is Logged In">
                      <CircleRounded
                        fontSize="small"
                        style={{
                          color: 'green',
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton>
                    <Tooltip title="User is not Logged In">
                      <CircleRounded
                        fontSize="small"
                        style={{
                          color: 'red',
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </>
              )}
              <IconButton
                onClick={() => {
                  setUserData(row.original);
                  setOpenUserProfileDialog(true);
                }}
              >
                <Avatar
                  src={`${serverURLForImages}/${row?.original?.profile_image}?timestamp=${Date.now()}`}
                  alt={row?.original?.first_name?.charAt(0) + row?.original?.lastname?.charAt(0)}
                />
              </IconButton>
              <IconButton
                onClick={() => {
                  setUserData(row.original);
                  setOpenResetPassword(true);
                }}
              >
                <LockReset />
              </IconButton>
            </Box>
          </>
        )}
      />

      {openResetPassword && (
        <ResetPassword
          open={openResetPassword}
          onClose={() => setOpenResetPassword(false)}
          updateUser={userData}
          onUpdate={(values, actions) => {
            handleChangePassword(values, actions);
          }}
        />
      )}

      {openUserProfileDialog && (
        <UpdateUserDialog
          open={openUserProfileDialog}
          onClose={() => setOpenUserProfileDialog(false)}
          updateUser={userData}
          onUpdate={(values, actions) => {
            handleUpdateUser(values, actions);
          }}
        />
      )}
    </>
  );
}

export default Users;
