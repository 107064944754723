import { Box, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { API_URLS } from 'src/constants/apiURLs';
import { enqueueSnackbar } from 'notistack';
import MaterialReactTable from 'material-react-table';
import { Get } from 'src/actions/API/apiActions';
import { formatDistanceToNow } from 'date-fns';
import { CSVLink } from 'react-csv'; // Import CSVLink from react-csv
import { Icon } from '@iconify/react';
function OpenedEmailsTables({ fromDate, toDate, campaign }) {
  // table states
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openedEmails, setOpenedEmails] = useState([]);
  const [count, SetCount] = useState(0);
  const columns = useMemo(
    () => [
      {
        accessorKey: 'person.full_name',
        header: 'Name',
        minWidth: 170,
      },
      {
        accessorKey: 'email_id',
        header: 'Email',
        enableClickToCopy: true,
        minWidth: 170,
      },
      {
        accessorKey: 'abc',
        header: 'Status',
        enableClickToCopy: true,
        minWidth: 170,
        Cell: ({ cell }) => (
          <Box component="span">
            <>
              <Typography
                variant="body2"
                style={{
                  color: 'white',
                  backgroundColor: '#89C885',
                  padding: '5px',
                  borderRadius: '5px',
                  width: '100px',
                  textAlign: 'center',
                }}
              >
                Opened
              </Typography>
            </>
          </Box>
        ),
      },
      {
        accessorKey: 'user.first_name',
        header: 'Lead Expert',
        minWidth: 170,
      },
      // {
      //   accessorKey: 'createdAt',
      //   header: 'Date',
      //   minWidth: 170,
      //   Cell: ({ cell }) => {
      //     const date = new Date(cell.getValue());
      //     return <Typography variant="body2">{formatDistanceToNow(date, { addSuffix: true })}</Typography>;
      //   },
      // },
    ],
    [] // Recalculate when counters change
  );

  const getRecentOpenedEmails = useCallback(() => {
    try {
      setIsLoading(true);
      Get(
        {
          fromDate: fromDate,
          toDate: toDate,
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
        },
        API_URLS.getRecentOpenedEmails,
        (resp) => {
          console.log('resp', resp);
          setOpenedEmails(resp?.data?.response);
          SetCount(resp?.data?.count);
          setIsLoading(false);
        },
        (error) => {
          console.log('error', error);
          setIsError(true);
          setIsLoading(false);
        }
      );
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
      setIsError(true);
      setIsLoading(false);
    }
  }, [fromDate, toDate, pagination.pageIndex, pagination.pageSize, enqueueSnackbar]);

  useEffect(() => {
    getRecentOpenedEmails();
  }, [getRecentOpenedEmails]);

  const csvData = openedEmails?.map((email) => ({
    Name: email?.person?.full_name,
    Email: email?.email,
    Status: 'Opened',
    Addedby: email?.user?.first_name + ' ' + email?.user?.last_name,
    Date: new Date(email?.createdAt).toLocaleDateString(),
  }));

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ marginLeft: '1rem' }} variant="h4">
          Opened Emails
        </Typography>
        <CSVLink data={csvData} style={{ marginLeft: '10px' }} filename="opened-emails.csv">
          <Tooltip title="Download" arrow>
            <Icon icon="ic:outline-download" style={{ fontSize: '25px' }} />
          </Tooltip>
        </CSVLink>
      </Box>
      <div style={{ margin: '1rem' }}>
        <MaterialReactTable
          columns={columns}
          data={openedEmails}
          rowCount={count}
          enableColumnActions={false}
          enableColumnFilters={false}
          enableTopToolbar={false}
          defaultColumn={{
            size: 50,
          }}
          manualPagination
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          muiTableBodyRowProps={{ hover: false }}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            sorting,
          }}
          muiTableProps={{
            sx: {
              borderRadius: '30px',
            },
          }}
        />
      </div>
    </>
  );
}

export default OpenedEmailsTables;
